import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext'; 

const ProtectedRoute = ({ children }) => {
  const { country } = useLanguage(); 
  const { countryCode } = useParams(); 
  const location = useLocation();

  const restrictedCountries = ['EN'];

  if (restrictedCountries.includes(country) || restrictedCountries.includes(countryCode.toUpperCase())) {
    
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return children; 
};

export default ProtectedRoute;
