
class InsuranceHelper {

  static getCountryCodeFromPath() {
    const pathParts = window.location.pathname.split('/');
    const countryCode = pathParts[1];
    return countryCode.toUpperCase();
  }

  // Get insurance data based on the country code
  static getInsuranceInitData() {
    const storedData = localStorage.getItem("InsuranceInitData");

    if (storedData) {
      try {
        const allCountriesData = JSON.parse(storedData);
        const countryCode = InsuranceHelper.getCountryCodeFromPath();
        return allCountriesData[countryCode] || null; // If data for countryCode not found, return null
      } catch (error) {
        console.error("Error parsing JSON from localStorage:", error);
        return null; // Return null if there's an error in parsing
      }
    }

    // Return null if no data found
    return null;
  }

  static getTotal() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return data.totalAmount || 0;
    }      
    return 0;      
  }

  static getPremium() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return data.premiumAmount || 0;
    }      
    return 0;      
  }

  static getVAT() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return data.vatAmount || 0;
    }
    return 0;      
  }

  static getVATExt() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return data.vatExtAmount || 0;
    }
    return 0;
  }

  static getVATExt2() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return data.vatExt2Amount || 0;
    }
    return 0;
  }

  static getIpidURL() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return data.ipidURL;
    } else {
      return "";
    }
  }

  static getTermsAndConditionsURL() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return data.termsAndConditionsURL;
    } else {
      return "";
    }      
  }

  static getPrivacyPolicyURL() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return data.privacyPolicyURL;
    } else {
      return "";
    }
  }

  static getClaimsFormURL() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return data.claimsFormURL;
    } else {
      return "";
    }
  }

  static getFicURL() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return data.ficURL;
    } else {
      return "";
    }
  }

  static getDocuments() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return data.documentList;
    } else {
      return [];
    }
  }  
  
  
}

export default InsuranceHelper;