import React, { useEffect, useState } from "react";
import { useLanguage } from "../contexts/LanguageContext";
import Button from "./Button";
import { Trans, useTranslation } from "react-i18next";
import InsuranceHelper from "../helpers/InsuranceHelper";

const DetailsForm = ({
  form,
  plan,
  handlePayment,
  handleEdit
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState(form);
  const { country, setCountry } = useLanguage();
  const [priceCountry, setPriceCountry] = useState(country);
  const [vatCountryPercentage, setVatCountryPercentage] = useState("");
  const [vatCountry, setVatCountry] = useState("");
  const [premie, setPremie] = useState(0);
  const [expectedEndDate, setExpectedEndDate] = useState("");

  
  useEffect(() => {
    window.scrollTo(0, 0);

    /*
    if (country === "FR" && data.countryCode !== "FR") {
      handleEdit(data); // Redirect to edit if user changes to FR.
    } else if (
      (country === "IT" && data.countryCode !== "IT") ||
      (country === "ES" && data.countryCode !== "ES")
    ) {
      handleEdit(data);
    } else {
      // Update data with the new country
      setData((prevData) => ({
        ...prevData,
        countryCode: country,
      }));
    }
    */

    console.log("DetailForm. data",data);

    const deliveryDate = new Date(data.expectedDeliveryDate);
    deliveryDate.setMonth(deliveryDate.getMonth() + 6);

    const formattedEndDate = `${deliveryDate.getFullYear()}-${(
      deliveryDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${deliveryDate.getDate().toString().padStart(2, "0")}`;
    setExpectedEndDate(formattedEndDate);

    if (data.countryCode !== "FR") {
      data.placeOfBirthCountry = "";
    }

    if (data.countryCode !== "FR") {
      data.placeOfBirthCity = "";
    }

    if (data.countryCode !== "IT" && data.countryCode !== "ES") {
      data.socialSecurity = "";
    }
  }, [ country ]);

  const textPlan =
    plan === "basic" ? "Basic plan" : t("detailsform.insurancetypevalue");
  const price = plan === "basic" ? 0 : priceCountry;

  const goPayment = () => {
    handlePayment();
  };

  const goEdit = () => {
    handleEdit(data);
  };

  const printCountry = (countryCode) => {
    switch (countryCode) {
      case "ES":
        return "España";
      case "FR":
        return "France";
      case "IT":
        return "Italia";
      case "DE":
        return "Deutschland";
      default:
        return "";
    }
  };

  return (
    <div className="text-[16px] md:text-[18px] max-w-[1728px] mx-auto">
     
      <div className="mb-4 ">
        <label htmlFor="name" className="block mb-1">
          {t("detailsform.insurancetype")}
        </label>
        <div className="font-semibold bg-babymamGray-100 py-[15px] px-[20px] mt-2">
          {textPlan}
        </div>
      </div>
      <div className="mb-4">
        <label htmlFor="name" className="block mb-1">
          {t("detailsform.country")}
        </label>
        <div className="font-semibold bg-babymamGray-100 py-[15px] px-[20px] mt-2">
          <span className="uppercase">{printCountry(data.countryCode)}</span>
        </div>
      </div>
      <div className="mb-4">
        <div className="font-semibold bg-babymamGray-100 py-[15px] px-[20px] mt-2">
          {data.firstName} {data.lastName}
          <br />
          {data.phoneCountryCode} {data.phoneNumber}
          <br />
          {data.email}
        </div>
      </div>
      <div className="mb-4">
        <label htmlFor="name" className="block mb-1">
          {t("detailsform.address")}
        </label>
        <div className="font-semibold bg-babymamGray-100 py-[15px] px-[20px] mt-2">
          {data.streetAddress},{data.extField}
          <br />
          {data.postalCode} {data.city}
        </div>
      </div>

      {data.countryCode === "IT" || data.countryCode === "ES" ? (
        <div className="mb-4">
          <label htmlFor="name" className="block mb-1">
            {t("detailsform.socialsecurity")}
          </label>
          <div className="font-semibold bg-babymamGray-100 py-[15px] px-[20px] mt-2">
            {data.socialSecurity}
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="mb-8">
        <label htmlFor="name" className="block mb-1">
          {t("detailsform.dateofbirth")}
        </label>
        <div className="font-semibold bg-babymamGray-100 py-[15px] px-[20px] mt-2">
          {data.dateOfBirth}
        </div>
      </div>

      {data.countryCode === "FR" ? (
        <div>
          <div className="mb-8">
            <label htmlFor="name" className="block mb-1">
              {t("detailsform.placeofbirth")}
            </label>
            <div className="font-semibold bg-babymamGray-100 py-[15px] px-[20px] mt-2">
              {data.placeOfBirthCity}, {data.placeOfBirthCountry}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="mb-4">
        <label htmlFor="name" className="block mb-1">
          {t("detailsform.delivery")}
        </label>
        <div className="font-semibold bg-babymamGray-100 py-[15px] px-[20px] mt-2">
          {data.expectedDeliveryDate}
        </div>
      </div>

      <div className="mb-4">
        <label htmlFor="name" className="block mb-1">
          {t("detailsform.twins")}
        </label>
        <div className="font-semibold bg-babymamGray-100 py-[15px] px-[20px] mt-2">
          {data.havingTwins ? (
            <div className="mt-2">
              <span className="mt-1">{t("detailsform.twinsyes")} </span>
            </div>
          ) : (
            <span className="mt-1">{t("detailsform.twinsno")}</span>
          )}
        </div>
      </div>

      {data.partnerDateOfBirth || data.partnerName ? (
        <div className="mb-8">
          <label htmlFor="name" className="block mb-1">
            {t("detailsform.partner")}
          </label>
          <div className="font-semibold bg-babymamGray-100 py-[15px] px-[20px] mt-2">
            {data.partnerName}
            <br />
            {data.partnerDateOfBirth}
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="text-[18px] text-babymam-900 justify-between ">
        <div className="flex flex-row justify-between leading-[30px]">
          <span>{t("detailsform.premie")} </span>
          <span>{InsuranceHelper.getPremium()} €</span>
        </div>
        {country === "ES" ? (
          <>
            <div className="flex flex-row justify-between mt-[5px]">
              <span >
                Consorcio:{" "}
              </span>
              <span >
              { InsuranceHelper.getVATExt() } € 
              </span>
            </div>
            <div className="flex flex-row justify-between mt-[5px]">
              <span >
                L.E.A:{" "}
              </span>
              <span >
              { InsuranceHelper.getVATExt2() } €
              </span>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="flex flex-row justify-between leading-[30px]">
          <span>
            {t("detailsform.vat")}
          </span>
          <span>{InsuranceHelper.getVAT()} €</span>
        </div>
      </div>

      <div className="flex text-[20px] text-babymam-900 justify-between mb-8">
        <span>{t("detailsform.total")} </span>
        <span>{InsuranceHelper.getTotal()} €</span>
      </div>

      <div>
        <Button desk="20" mobile="16" theme="white" onClick={goEdit}>
          {t("detailsform.edit")}
        </Button>
        <div className="mt-2">
          <Button desk="20" mobile="16" onClick={goPayment}>
            {t("detailsform.go")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DetailsForm;
