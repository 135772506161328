// LanguageProvider.jsx
import React, { createContext, useContext, useState, useEffect } from "react";
import i18n from "../i18n";

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const [country, setCountry] = useState(""); // Start with empty string, not a detected value

  useEffect(() => {
    // Sync i18n with `country` state whenever `country` changes
    if (country) {
      i18n.changeLanguage(country.toLowerCase());
    }
  }, [country]);

  return (
    <LanguageContext.Provider value={{ country, setCountry }}>
      {children}
    </LanguageContext.Provider>
  );
};

