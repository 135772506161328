/* global fbq */

import React, { useEffect, useState } from "react";
import SignForm from "../components/SignUpForm";
import { useTranslation, Trans } from "react-i18next";
import i18n from "../i18n";
import { useLocation, useNavigate } from "react-router-dom";
import NewStepper from "../components/NewStepper";
import { useLanguage } from "../contexts/LanguageContext";
import InsuranceHelper from "../helpers/InsuranceHelper";

const Checkout = () => {
  const { t } = useTranslation();
  const { country, setCountry } = useLanguage();
  const [data, setData] = useState({});
  const location = useLocation();
  const [language, setLanguage] = useState();
  const plan = location.state?.plan;
  const [isPayment, setIsPayment] = useState(false);
  const navigate = useNavigate();
  const [title, setTitle] = useState("checkout.complete_data");
  const [subtitle, setSubtitle] = useState("");


  useEffect(() => {
    if (!country) return;

    // Retrieve data from sessionStorage on component mount
    const insuranceForm = sessionStorage.getItem("insuranceForm");
    if (insuranceForm) {
      const parsedData = JSON.parse(insuranceForm);
      setData(parsedData);
    }
  }, [country, navigate]);

  const handleForm = (data) => {
    const language = i18n.language;
    data._formValidated = true;
    sessionStorage.setItem('insuranceForm', JSON.stringify(data));

    const code = language.toLowerCase();
    navigate(`/${code}/product/summary`);
  };

  const textPlan = plan === "basic" ? "Basic plan" : t("confirmationsummary.insurance");

  const [currentStep, setCurrentStep] = useState(1);
  const stepArray = [
    t("checkoutstepper.step1"),
    t("checkoutstepper.step2"),
    t("checkoutstepper.step3"),
    t("checkoutstepper.step4"),
  ];

  return (
    <>
      <div className="w-full md:px-[60px] justify-center justify-items-center">
        <div className="md:mb-20 md:px-20 max-w-[1728px] mx-auto">
          <NewStepper steps={stepArray} currentStepNumber={currentStep} />
        </div>
      </div>
  
      <div className="w-full p-5 md:p-10 bg-white md:bg-babymamGray-100 justify-center justify-items-center">
        <div className="justify-center text-center pb-5 md:pb-10 max-w-[1728px] mx-auto">
          <h2 className="headline-big">
            {t(title)}
          </h2>
          <p className="mt-4 text-[18px] md:text-[20px]">{t(subtitle)}</p>
        </div>
  
        <div className="flex-col md:flex-row flex md:px-10 justify-center max-w-[1728px] mx-auto">
          <div className="bg-white rounded-lg p-5 md:p-10 mb-6 md:w-6/12">
            {/* Render DetailsForm only when data is populated */}
            {data ? (
              <SignForm
                handleForm={handleForm}
                data={data}
                plan={plan}
                language={language}
              />
            ) : (
              <p>Loading...</p> // Optional loading indicator or message
            )}
          </div>
          
          <div
            className={`${
              plan === "basic" ? "bg-babymamGray-200" : "bg-babymam-100"
            } rounded-lg px-5 py-4 h-fit ${
              isPayment ? "mx-auto w-6/12 mb-10" : "md:w-4/12 md:ml-10"
            } sticky`}
            style={{ top: "140px" }}
          >
            <div className="border-b-2 border-babymam-400 pb-4 font-semibold text-babymam-900 text-[18px] md:text-[22px] text-center">
              {t("signformsummary.summary")}
            </div>
            <div className="py-4 flex flex-col text-babymam-900 text-[18px] md:text-[20px]">
              <div className="flex flex-col justify-between">
                <span>{t("signformsummary.chosen")} </span>
                <span className="font-semibold">{textPlan}</span>
              </div>
              <div className="flex flex-row justify-between mt-5">
                <span className="text-[14px] md:text-[16px] leading-[18px]">
                  {t("signformsummary.premie")}{" "}
                </span>
                <span className="text-[14px] md:text-[16px] leading-[18px]">
                  {InsuranceHelper.getPremium()} €
                </span>
              </div>
              {country === "ES" ? (
                <>
                  <div className="flex flex-row justify-between mt-[5px]">
                    <span className="text-[14px] md:text-[16px]  leading-[18px]">
                      Consorcio:{" "}
                    </span>
                    <span className="text-[14px] md:text-[16px] leading-[18px]">
                    {InsuranceHelper.getVATExt()} €
                    </span>
                  </div>
                  <div className="flex flex-row justify-between mt-[5px]">
                    <span className="text-[14px] md:text-[16px]  leading-[18px]">
                      L.E.A:{" "}
                    </span>
                    <span className="text-[14px] md:text-[16px] leading-[18px]">
                    {InsuranceHelper.getVATExt2()} €
                    </span>
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="flex flex-row justify-between mt-[5px]">
                <span className="text-[14px] md:text-[16px]  leading-[18px]">
                  {t("signformsummary.vat")}{" "}
                </span>
                <span className="text-[14px] md:text-[16px] leading-[18px]">
                  {InsuranceHelper.getVAT()} €
                </span>
              </div>
              <div className="mt-4 flex flex-row justify-between">
                <span className="text-[18px] md:text-[20px] font-semibold">
                  {t("signformsummary.total")}{" "}
                </span>
                <span className="font-semibold">{InsuranceHelper.getTotal()} €</span>
              </div>
            </div>
          </div>
        </div>
      </div>      
    </>
  );
};

export default Checkout;
