import React from "react";
import PhoneSection from "./sections/PhoneSection";
import FAQSection from "./sections/FAQSectionFull";
import { NavLink } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import Button from "../components/Button";
import InternalLink from "../components/InternalLink";

const FAQ = () => {
  const { t } = useTranslation();
  const faqs = t('faq_complete', { returnObjects: true }) || [];
  var collapsibleDataLeft = [];
  var collapsibleDataRight = [];

  if (Array.isArray(faqs) && faqs.length > 0) {
    // Determine the middle point, rounding up to include an odd item in the first array
    const middleIndex = Math.ceil(faqs.length / 2);
  
    // Split the array into two halves
    collapsibleDataLeft = faqs.slice(0, middleIndex);
    collapsibleDataRight = faqs.slice(middleIndex);
  }
  
  return (
    <>
      <div className="w-full py-10 px-5 md:p-0 md:pt-20 md:px-48 justify-center justify-items-center">
        <div className="w-full justify-center text-center">
          <div className="md:mx-auto">
            <h1 className="text-[28px] md:text-[36px] font-bold font-serif text-babymam-900">
              FAQ
            </h1>
          </div>
        </div>
      </div>

      <div className="w-full -mt-20 flex justify-center justify-items-center">
        
        <FAQSection
          dataLeft={collapsibleDataLeft}
          dataRight={collapsibleDataRight}
          principal={true}
          title={false}
        />
      </div>

      {/* FIND */}
      <div className="w-full p-10 md:px-48 md:py-[30px] bg-leafs bg-cover justify-center justify-items-center">
        <div className="w-full md:max-w-[1200px] justify-center text-center mx-auto">
          <div className="md:w-6/12 rounded md:mx-auto text-center">
            <h2 className="text-[22px] md:text-[28px] font-bold text-babymam-900 mb-6">
              {t("faq.find")}
            </h2>
            <InternalLink to="/contact">
              <Button >{t("faq.button")}</Button>
            </InternalLink>
          </div>
        </div>
      </div>



      {/* PHONES */}
      <PhoneSection faq={true} customImage={true}/>
    </>
  );
};

export default FAQ;
