import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useLanguage } from "../../contexts/LanguageContext";
import Button from "../../components/Button";
import InsuranceHelper from "../../helpers/InsuranceHelper";
import ContentHelper from "../../helpers/ContentHelper";
import InternalLink from "../../components/InternalLink";

import { ReactComponent as ArrowDown } from '../../images/keyboard_arrow_down.svg'; // Placeholder for arrow down icon
import { ReactComponent as ArrowUp } from '../../images/keyboard_arrow_up.svg'; // Placeholder for arrow up icon

const FAQSection = () => {
  const { t } = useTranslation();
  const { country } = useLanguage();
  const faqs = t('faq_start', { returnObjects: true }) || [];
  const [activeIndex, setActiveIndex] = useState(null); // Keep track of the active/expanded FAQ

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="max-w-[800px] mx-auto px-4 py-8">
      {/* Header */}
      <h2 className="headline-medium text-center mb-8">
        FAQs
      </h2>

      {/* FAQ List */}
      <div className="space-y-4">
        {Array.isArray(faqs) && faqs.map((faq, index) => {
          
          const modifiedTitle = ContentHelper.transformAppContent(faq.title, country)
          const modifiedContent = ContentHelper.transformAppContent(faq.content, country)

          return (
            <div key={index} className="border-b border-babymam-600 pb-4">
              <button
                className="w-full flex justify-between items-center text-left text-[18px] md:text-[20px] font-bold text-gray-900 py-2"
                onClick={() => toggleFAQ(index)}
              >
                {modifiedTitle}
                {activeIndex === index ? (
                  <ArrowUp className="text-babymam-600 w-6 h-6" />
                ) : (
                  <ArrowDown className="text-babymam-600 w-6 h-6" />
                )}
              </button>
              {/* FAQ Answer with smooth expand/collapse */}
              <div
                className={`transition-all duration-300 ease-in-out overflow-hidden ${
                  activeIndex === index ? 'max-h-[1200px]' : 'max-h-0'
                }`}
              >
                <div className="faq-item text-[14px] md:text-[16px] text-gray-700 mt-2" dangerouslySetInnerHTML={{ __html: modifiedContent }} />
              </div>
            </div>
          );
        })}
      </div>

      {/* See All FAQs Button */}
      <div className="mt-8 md:hidden">
        <InternalLink to={"/faq"}>
          <Button desk="20" mobile="18" theme='white'>
          {t('faq.all_faqs')}
          </Button>
        </InternalLink>
      </div>
      <div className="flex text-center mt-8 hidden md:block">
        <InternalLink to={"/faq"} className="inline-flex">
          <Button desk="20" mobile="18" fullWidth={false} theme='white'>
          {t('faq.all_faqs')}
          </Button>
        </InternalLink>
      </div>
    </div>
  );
};

export default FAQSection;