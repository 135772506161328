// LanguageRedirect.jsx
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLanguage } from "../contexts/LanguageContext";
import getLanguageBasedCountryCode from "../utils/getLanguageBasedCountryCode";
import i18n from "../i18n";


const LanguageRedirect = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { country, setCountry } = useLanguage();
    const [loading, setLoading] = useState(true); // Track loading state
  
    useEffect(() => {
      const path = location.pathname;
      const [ , languageCode, ...restOfPath ] = path.split('/'); // Extract language code and the rest of the path
      const supportedLanguages = ['en', 'it', 'es', 'de', 'fr'];
  
      if (supportedLanguages.includes(languageCode)) {
        if (country !== languageCode.toUpperCase()) {
          setCountry(languageCode.toUpperCase());
          i18n.changeLanguage(languageCode);
          console.log("i18n language set to:", languageCode);
        }
        setLoading(false); // Language is valid, set loading to false
      } else if (path === '/') {
        const detectedCountryCode = getLanguageBasedCountryCode();
        navigate(`/${detectedCountryCode}`, { replace: true });
      } else {
        const detectedCountryCode = getLanguageBasedCountryCode();
        navigate(`/${detectedCountryCode}`, { replace: true });
      }
  
      setLoading(false); // Complete loading after redirect logic
    }, [location, navigate, setCountry, country]);
  
    if (loading) return null; // Don't render until language is validated
  
    return null;
  };
  
  export default LanguageRedirect;