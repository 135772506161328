import axios from "axios";

const BASE_URL = process.env.NODE_ENV === "development"
  ? "https://botw-insurance-api-dev.azurewebsites.net/api"
  : "/api";


const apiFactory = () => {

  const checkInsurance = async (data) => {
    try {
      const url = `${BASE_URL}/pregnancyinsurance/get`;
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const createInsurance = async (data) => {
    try {
      const url = `${BASE_URL}/signup/pregnancy/create`;
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getInsurance = async (externalID) => {
    try {
      const url = `${BASE_URL}/pregnancyinsurance/get`;
      const response = await axios.post(url, {externalID}, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const setAwinCookie = async () => {
    try {
      const url = `${BASE_URL}/signup/pregnancy/cookies`;
      const response = await axios.post(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadDocument = async (countryCode) => {
    try {
      const url = `${BASE_URL}/signup/pregnancy/init`;
      const response = await axios.post(
        url,
        { countryCode },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const insuranceInit = async (countryCode) => {
    try {
      const url = `${BASE_URL}/signup/pregnancy/init`;
      console.log("==> insurance init "+countryCode);
      const response = await axios.post(
        url,
        { countryCode },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        }
      );
      //console.log("==> "+countryCode + " data:" + response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };



  return {
    checkInsurance,
    createInsurance,
    getInsurance,
    insuranceInit,
    setAwinCookie,
    handleDownloadDocument,
  };
};

export default apiFactory;
