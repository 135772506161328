import React from 'react';
import i18n from "../i18n";
import { Link, NavLink } from "react-router-dom";
import InternalLink from './InternalLink';
import { ReactComponent as InstagramIcon } from "../images/instagram_icon.svg"; // Replace with actual paths to your icons
import { ReactComponent as TikTokIcon } from "../images/tiktok_icon.svg";
import { ReactComponent as LogoIcon } from "../images/logo-babymam.svg";
import { useLanguage } from "../contexts/LanguageContext";
import { Trans, useTranslation } from "react-i18next";
import InsuranceHelper from "../helpers/InsuranceHelper";

const Footer = () => {

  const { country, setCountry, insuranceHelperLoading } = useLanguage();
  const { t } = useTranslation();

  return (
    <footer className="bg-babymam-100 w-full pt-8">
      <div className="">
        {/* Navigation Links */}
        <div className="max-w-[1200px] mx-auto flex flex-col md:flex-row justify-center text-center md:text-center mb-6">
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 px-8 text-left text-[18px] text-babymam-900 md:font-bold">
            {country !== "EN" && (
              <>
                <InternalLink to="/about">{t("footer.our_story")}</InternalLink>
                <InternalLink to="/contact">{t("footer.contact")}</InternalLink>
                <InternalLink to="/product">{t("footer.pregnancy")}</InternalLink>
                <InternalLink to="/faq">{t("footer.faqs")}</InternalLink>
              </>
            )}
          </div>
        </div>

        {/* Social Icons, Logo, and Legal Section with White Background */}
        <div className="w-full bg-white py-8">
          <div className="max-w-[1800px] mx-auto px-4">
            {/* Social Icons, Logo, and Legal Links */}
            <div className="flex flex-col md:flex-row justify-between items-center">
              {/* Social Icons */}
              <div className="flex space-x-12  md:space-x-12 mb-6 md:mb-0">
                <a href="https://instagram.com/babymamapp?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer">
                  <InstagramIcon className="w-6 h-6 text-babymam-600" />
                </a>
                <a href="https://www.tiktok.com/@baby_mam_app?_t=8qq5sdJ0UQD&_r=1" target="_blank" rel="noreferrer">
                  <TikTokIcon className="w-6 h-6 text-babymam-600" />
                </a>
              </div>

               {/* Logo */}
              <div className="mb-2 md:mb-0 flex flex-col items-center justify-center md:absolute md:left-1/2 md:transform md:-translate-x-1/2">
                  <LogoIcon className="w-36 h-auto" />
                  {/* Mobile view © Baby Mam 2023 */}
                  <span className="block md:hidden mt-4 mb-0 text-gray-600 text-[14px]">
                    © Baby Mam 2023
                  </span>
              </div>

              {/* Legal Links */}
              {country === "EN" ? (
                <div className="flex flex-row justify-center md:justify-end text-center text-[14px] md:text-right text-gray-600 space-x-4">
                  <span className="hidden md:inline">© Baby Mam 2023</span>
                  <InternalLink to="/cookies" className="text-babymam-600 font-bold underline">
                    Cookies
                  </InternalLink>
                </div>
              ) : country === "IT" ? (
                <div className="flex flex-row justify-center md:justify-end text-center text-[14px] md:text-right text-gray-600 space-x-4">
                  <span className="hidden md:inline">© Baby Mam 2023</span>
                  <Link to={InsuranceHelper.getTermsAndConditionsURL()} target="_blank" className="text-babymam font-bold underline">
                    { t("footer.ipid")}
                  </Link>
                  <InternalLink to="/cookies" className="text-babymam-600 font-bold underline">
                    Cookies
                  </InternalLink>
                </div>
              ) : country === "DE" ? (
                <div className="flex flex-row flex-wrap justify-center md:justify-end text-center text-[14px] md:text-right text-gray-600 space-x-4">
                  <span className="hidden md:inline">© Baby Mam 2023</span>
                  <Link to={InsuranceHelper.getIpidURL()} target="_blank" className="text-babymam-600 font-bold underline">
                    {t("footer.ipid")}
                  </Link>
                  <Link to={InsuranceHelper.getTermsAndConditionsURL()} target="_blank" className="text-babymam-600 font-bold underline">
                    {t("footer.terms")}
                  </Link>
                  <InternalLink to="/cookies" className="text-babymam-600 font-bold underline">
                    Cookies
                  </InternalLink>
                </div>
              ) : (
                <div className="flex flex-row justify-center md:justify-end text-center text-[14px] md:text-right text-gray-600 space-x-4">
                  <span className="hidden md:inline">© Baby Mam 2023</span>
                  <Link to={InsuranceHelper.getIpidURL()} target="_blank" className="text-babymam-600 font-bold underline">
                    { t("footer.ipid")}
                  </Link>
                  <Link to={InsuranceHelper.getTermsAndConditionsURL()} target="_blank" className="text-babymam-600 font-bold underline">
                    { t("footer.terms")}
                  </Link>
                  <InternalLink to="/cookies" className="text-babymam-600 font-bold underline">
                    Cookies
                  </InternalLink>
                </div>
              )}
              </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;