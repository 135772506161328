import React, { useEffect, useState, useRef, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
/* import { toast } from "react-toastify"; */
import "react-toastify/dist/ReactToastify.css";
import Checkbox from "./Checkbox";
import { NavLink } from "react-router-dom";
import Button from "./Button";
import Input from "./Input";
import DatePickerInput from "../components/DatePickerInput/DatePickerInput";
import apiFactory from "../helpers/apiFactory";
import { toast } from "react-toastify";
import { useParam } from "../contexts/ParamContext";
import { ReactComponent as Error } from "../images/error-outline.svg";
import { useLanguage } from "../contexts/LanguageContext";
import { ReactComponent as Arrow } from "../images/south-arrow.svg";
import { ReactComponent as Close } from "../images/close.svg";
import InsuranceHelper from "../helpers/InsuranceHelper";
import { ReactComponent as ExpandIcon } from "../images/expand_more.svg";

const SignForm = ({
  handleForm,
  data,
  plan,
  language
}) => {
  const { t, i18n } = useTranslation();
  const { country } = useLanguage();
  const { param } = useParam();
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    lastName: "",
    socialSecurity: "",
    phoneNumber: "",
    streetAddress: "",
    extField: "",
    postalCode: "",
    city: "",
    dateOfBirth: "",
    expectedDeliveryDate: "",
    countryCode: country,
    phoneCountryCode: "",
    placeOfBirthCity: "",
    placeOfBirthCountry: "",
    havingTwins: false,
    childCount: 0,
  });

  const [selectedBirthDate, setSelectedBirthDate] = useState(null);
  const [selectedBirthDatePartner, setSelectedBirthDatePartner] =
    useState(null);
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [isResident, setIsResident] = useState(true);
  const [showError, setShowError] = useState(false);
  const [errors, setErrors] = useState({});
  const [isCheckedTwins, setIsCheckedTwins] = useState(formData.havingTwins);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isChecked4, setIsChecked4] = useState(false);
  const [isChecked5, setIsChecked5] = useState(false);
  const [showConsentPopup, setShowConsentPopup] = useState(false);
  //rubbish "component states" needing to be keep in the parent. fxxk react!
  const [isConsentButtonEnabled, setIsConsentButtonEnabled] = useState(false);
  const [showArrow, setShowArrow] = useState(false); //arrow gone for now
  const [consentScrollPos, setConsentScrollPos] = useState(0);

  const translations = {
    ES: {
      label:
        "Documento Nacional de Identidad (DNI) o Número de Identificación de Extranjero (NIE)  (Madre)",
      placeholder: "Escribe tu número de DNI o NIE",
    },
    IT: {
      label: "Codice fiscale (madre)",
      placeholder: "Scrivi qui il tuo codice fiscale [XXXXXX00X00X000X]",
    },
    FR: {
      label: "Numéro de Sécurité Sociale (Mère)",
      placeholder: "Renseignez votre numéro de Sécurité Sociale",
    },
    DE: {
      label: "Sozialversicherungsnummer",
      placeholder: "[XXXXXXXXXXXX]",
    },
  };
  const label = translations[language]?.label || "Default label";
  const placeholder =
    translations[language]?.placeholder || "Default placeholder";

  const isButtonDisabled = Object.keys(formData).some((value, key) => {
    if (
      value === "partnerName" ||
      value === "extField" ||
      value === "partnerDateOfBirth" ||
      (key === "socialSecurity" && (country !== "ES" || country !== "IT"))
    ) {
      return false;
    }

    if (
      value === "havingTwins" &&
      formData.havingTwins &&
      formData.childCount <= 0
    ) {
      return true;
    }

    if (value === "socialSecurity") {
      return (
        (formData.countryCode === "IT" && formData[value].trim() === "") ||
        errors.socialSecurity ||
        (formData.countryCode === "ES" && formData[value].trim() === "") ||
        errors.socialSecurity
      );
    }

    if (
      key === "socialSecurity" &&
      (country === "IT" || country === "ES") &&
      (!value.trim() || errors.socialSecurity)
    ) {
      return true;
    }
    if (
      (formData.countryCode === "IT" || formData.countryCode === "ES") &&
      !formData.socialSecurity.trim()
    ) {
      if (formData.countryCode === "IT") {
        errors.socialSecurity = "Codice fiscale non valido.";
      } else if (formData.countryCode === "ES") {
        errors.socialSecurity = "Número de identificación no válido";
      }
    }

    if (value === "placeOfBirthCity") {
      return formData.countryCode === "FR" && formData[value].trim() === "";
    }

    if (value === "placeOfBirthCountry") {
      return formData.countryCode === "FR" && formData[value].trim() === "";
    }

    if (formData[value] === "" && formData[value].mandatory) {
      return true;
    }

    if (!(isChecked1 && isChecked2 && isChecked3 && isChecked4 && isChecked5)) {
      return true;
    }

    if (emailError) {
      return true;
    }
    if (!isResident) {
      return true;
    }

    if (value === "phoneNumber" && !!errors.phoneNumber) {
      return true;
    }

    return false;

    // return formData[value].trim() === "" || isChecked === false;

    
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    validateInsuranceNumber();

    const newErrors = {};

    const ignoredFields = new Set([
      "extField",
      "partnerName",
      "partnerDateOfBirth",
      "placeOfBirthCountry",
      "placeOfBirthCity",
      "socialSecurity"
    ]);
    
    // Remove specific ignored fields for France (FR)
    if (country === "FR") {
      ignoredFields.delete("placeOfBirthCountry");
      ignoredFields.delete("placeOfBirthCity");
    }
    
    for (const [field, value] of Object.entries(formData)) {
      if (ignoredFields.has(field)) continue;
    
      if (typeof value === "string" && (value.trim() === "" || value.length < 2)) {
        newErrors[field] = t("signform.mandatoryfield_error");
      }
    }

    // Check if there are any errors in the form data
    const hasErrors =
      Object.keys(newErrors).length > 0 || !!errors.socialSecurity;

    setErrors(newErrors); // Update the errors state

    if (!hasErrors) {
      // Proceed with form submission
      formData.productType = plan === "basic" ? "FREE" : "PREGNANCY_PAID";
      // formData._botwCountryCode = "";

      if (formData.dateOfBirth.includes("/")) {
        const [dayBirth, monthBirth, yearBirth] =
          formData.dateOfBirth.split("/");
        formData.dateOfBirth = `${yearBirth}-${monthBirth}-${dayBirth}`;
      }
      if (formData.partnerDateOfBirth?.includes("/")) {
        const [dayBirth, monthBirth, yearBirth] =
          formData.partnerDateOfBirth.split("/");
        formData.partnerDateOfBirth = `${yearBirth}-${monthBirth}-${dayBirth}`;
      }
      if (formData.expectedDeliveryDate.includes("/")) {
        const [dayDelivery, monthDelivery, yearDelivery] =
          formData.expectedDeliveryDate.split("/");
        formData.expectedDeliveryDate = `${yearDelivery}-${monthDelivery}-${dayDelivery}`;
      }
      // Check for param in localStorage
      const storedParam = localStorage.getItem("param");
      if (storedParam) {
        formData.salesRef = storedParam;
      } else if (param) {
        formData.salesRef = param;
      }

      formData.countryCode = country

      handleForm(formData);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    console.log("handleInputChange, id = ", id, "formData", formData);

    if (id === "phoneNumber") {
      const phoneRegex = /^\d+$/;
      const isValidPhone =
        phoneRegex.test(value) && value.length >= 9 && value.length <= 15;

      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));

      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        if (isValidPhone) {
          delete newErrors.phoneNumber;
        } else {
          newErrors.phoneNumber = (
            <div className="flex center mt-2">
              <Error />
              <span className="ml-1 text-red-600">
                {t("signform.phoneerror")}
              </span>
            </div>
          );
        }
        return newErrors;
      });
    } else if (id === "email") {
      const emailRegex =
        /^[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,5}$/;
      const isValidEmail = emailRegex.test(value);

      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));

      setEmailError(
        isValidEmail ? (
          ""
        ) : (
          <div className="flex center mt-2">
            <Error />
            <span className="ml-1 text-red-600">
              {t("signform.emailerror")}
            </span>
          </div>
        )
      );
    } else if (id === "countryCode" && value !== "FR") {
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
        placeOfBirthCountry: "",
      }));
    } else if (id === "socialSecurity") {
      // Validación de seguridad social al cambiar
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));

      validateInsuranceNumber();
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const validateInsuranceNumber = () => {
    const { countryCode, socialSecurity } = formData;
    const newErrors = { ...errors };

    //console.log("validateInsuranceNumber, country = ", country, " countryCode = ", countryCode, "formData: ", formData);

    switch (country) {
      case "ES": // Spain
        const spanishPattern = /^(?:[XYZ]\d{7}|[0-9]{8})[A-Z]$/i;
        if (!spanishPattern.test(socialSecurity)) {
          newErrors.socialSecurity = (
            <div className="flex center mt-2">
              <Error />{" "}
              <span className="ml-1 text-red-600">
                Número de identificación no válido
              </span>
            </div>
          );
        } else {
          delete newErrors.socialSecurity; // Eliminar error si el formato es válido
        }
        break;

      case "IT": // Italy
        const italyPattern = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i;
        if (!italyPattern.test(socialSecurity)) {
          newErrors.socialSecurity = (
            <div className="flex center mt-2">
              <Error />{" "}
              <span className="ml-1 text-red-600">
                Codice fiscale non valido
              </span>
            </div>
          );
        } else {
          delete newErrors.socialSecurity;
        }
        break;

      case "FR": // FRANCE
        const francePattern = /^[12]\d{2}(0[1-9]|1[0-2]|[2-9]\d)(0[1-9]|[1-9]\d|2[AB])\d{6}\d{2}$/i;
        if (!francePattern.test(socialSecurity)) {
          newErrors.socialSecurity = (
            <div className="flex center mt-2">
              <Error />{" "}
              <span className="ml-1 text-red-600">
                Numéro de sécurité sociale invalide.
              </span>
            </div>
          );
        } else {
          delete newErrors.socialSecurity;
        }
        break;

      default:
        delete newErrors.socialSecurity;
        break;
    }

    setErrors(newErrors);
  };

  const ipidNavLink = (
    <a
      className="font-semibold text-babymam-600"
      href={InsuranceHelper.getIpidURL()}
      target="_blank"
    >
      <span>{t('signform.ipid')}</span>
    </a>
  );

  const termsNavLink = (
    <a
      className="font-semibold text-babymam-600"
      href={InsuranceHelper.getTermsAndConditionsURL()}
      target="_blank"
    >
    </a>
  );

  const ficNavLink = (
    <a
      className="font-semibold text-babymam-600"
      href={InsuranceHelper.getFicURL()}
      target="_blank"
    >
    </a>
  );

  const masterPolicyLink = (
    <a
      className="font-semibold text-babymam-600"
      href={t('signform.chubbMastePolicyUrl')}
      target="_blank"
      rel="noopener noreferrer">
    </a>
  );

  const defaultTransformComponents = { linkIpid: ipidNavLink, linkTerms: termsNavLink, linkMasterPolicy: masterPolicyLink, linkFic: ficNavLink, b : <br />, span: <span />, p: <p /> }
  
  const checkLabel1 = (
    <span className="text-[14px] leading-[18px]">
      {t("signform.checklabel1")}
    </span>
  );

  const checkLabel2 = (
    <span className="text-[14px] leading-[18px]">
      <Trans components={defaultTransformComponents} >
      {t("signform.checklabel2")}
      </Trans>
    </span>
  );

  const checkLabel3 = (
    <span className="text-[14px] leading-[18px]">
      <Trans components={defaultTransformComponents} >
      {t("signform.checklabel3")}
      </Trans>
    </span>
  );

  // Extra checkbox, only used by DE?
  const useChecklabel4 = i18n.exists('signform.checklabel4');
  const checkLabel4 = (
    <span className="text-[14px] leading-[18px]">
      <Trans components={defaultTransformComponents} >
      {t("signform.checklabel4")}
      </Trans>
    </span>
  );

  useEffect(() => {
    if(!useChecklabel4) {
      setIsChecked4(true)
    }    
  })
  
  const checkLabel5 = (
    <span className="text-[14px] leading-[18px]">
      <Trans components={defaultTransformComponents} >
      {t("signform.checklabel5")}
      </Trans>
    </span>
  );

  const handleCheckboxChange = (checkboxNumber) => {
    switch (checkboxNumber) {
      case 1:
        setIsChecked1(!isChecked1);
        break;
      case 2:
        setIsChecked2(!isChecked2);
        break;
      case 3:
        setIsChecked3(!isChecked3);
        break;
      case 4:
        setIsChecked4(!isChecked4);
        break;
      case 5:
        //   setIsChecked3(!isChecked3);
        handleShowConsent();
        break;
      default:
        break;
    }
  };

  const handleShowConsent = () => {
    if (isChecked5) {
      setIsChecked5(false);
    }
    setShowConsentPopup(true);
  };

  const handleAcceptConsent = useCallback(() => {
    setIsChecked5(true);
    setShowConsentPopup(false);
    setConsentScrollPos(0);
    setIsConsentButtonEnabled(false);
  }, []);

  const handleCloseConsent = useCallback(() => {
    setShowConsentPopup(false);
    setConsentScrollPos(0);
    setShowArrow(false);
    setIsConsentButtonEnabled(false);
  }, []);

  const handleCheckboxTwinsChange = (e) => {
    const value = e.target.value === "true";
    setFormData({
      ...formData,
      havingTwins: value,
      childCount: value ? formData.childCount : 0,
    });
  };

  const handleChildCountChange = (e) => {
    setFormData({
      ...formData,
      childCount: parseInt(e.target.value),
    });
  };

  const handleResident = (e) => {
    const isResident = e.target.value === "yes";
    setIsResident(isResident);
    setShowError(!isResident);
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleBirthDate = (date) => {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    let newErrors = { ...errors };
    if (age < 18) {
      newErrors.dateOfBirth = t("signform.dateminerror");
    } else if (age > 50) {
      newErrors.dateOfBirth = t("signform.datemaxerror");
    } else {
      setSelectedBirthDate(date);
      setFormData((prevData) => ({
        ...prevData,
        dateOfBirth: formatDate(date),
      }));
      delete newErrors.dateOfBirth;
    }

    setErrors(newErrors);
  };

  const handleBirthDatePartner = (date) => {
    setSelectedBirthDatePartner(date);
    setFormData({ ...formData, partnerDateOfBirth: formatDate(date) });
  };

  const handleDeliveryDate = (date) => {
    setSelectedDeliveryDate(date);
    setFormData({ ...formData, expectedDeliveryDate: formatDate(date) });
  };

  const updatePhoneCountryCode = (countryCode) => {
    const countryPhoneCodes = {
      ES: "+34",
      IT: "+39",
      FR: "+33",
      DE: "+49",
    };
    const phoneCode = countryPhoneCodes[countryCode] || "";
    setFormData((prevFormData) => ({
      ...prevFormData,
      phoneCountryCode: phoneCode,
    }));
  };

  const ConsentPopup = ({ onClose, onAccept, isConsentButtonEnabled, setIsConsentButtonEnabled, showArrow, setShowArrow, consentScrollPos, setConsentScrollPos }) => {
    const popupContentRef = useRef(null);

    const handleScroll = () => {
      if (popupContentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = popupContentRef.current;
        
        if (scrollTop + clientHeight + 30 >= scrollHeight) {
          if(!isConsentButtonEnabled) {
            setConsentScrollPos(popupContentRef.current.scrollTop);
            setShowArrow(false);
            setIsConsentButtonEnabled(true);
          }          
        }
      }
    };

    const scrollToEnd = () => {
      if (popupContentRef.current) {
        popupContentRef.current.scrollTop = popupContentRef.current.scrollHeight;    
        setConsentScrollPos(popupContentRef.current.scrollTop);
      }
      setShowArrow(false);
      setIsConsentButtonEnabled(true);     
    }
    
    useEffect(() => {
      const popupElement = popupContentRef.current;
      if (popupElement) {
        popupElement.addEventListener("scroll", handleScroll);
        popupElement.scrollTop = consentScrollPos;
      }

      return () => {
        if (popupElement) {
          popupElement.removeEventListener("scroll", handleScroll);
        }
      };
    }, []);

    const renderButton = () => {
      if (isConsentButtonEnabled) {
        return (
          <Button onClick={onAccept}>
            {t("consent.button")}
          </Button>
        );
      }
      return (
        <Button onClick={scrollToEnd} theme="white" >
          {t("consent.button_scrollend")}
          <ExpandIcon  className="inline-block" style={{ marginLeft: "8px" }} />
        </Button>
      );
    };

    const clickBlocker = (event) => {
      event.stopPropagation();
    }

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50 w-full" onClick={onClose}>
        <div className="bg-white pt-[40px] pb-[20px] pl-[20px] pr-[30px] md:pl-[40px]  rounded-md w-[320px] md:w-[420px] md:max-w-[420px] h-[511px] md:h-[350px] relative flex flex-col" onClick={clickBlocker}>
          <div className="absolute right-[24px] md:right-[25px] top-3">
            <button
              onClick={onClose}
              className="text-babymam-600 focus:outline-none"
            >
              <Close />
            </button>
          </div>
          <div
            className="overflow-auto flex-grow md:pr-[10px] scrollbar-thin scrollbar-webkit"
            ref={popupContentRef}
          >
            <div className="mb-6 text-left">
              <p className="mb-4">
                <Trans components={{ span: <span />, a: <a /> }}>
                  {t("consent.paragraph1")}
                </Trans>
              </p>
              <p className="mb-4">
                <Trans components={{ span: <span />, a: <a /> }}>
                  {t("consent.paragraph2")}
                </Trans>
              </p>
              <p className="mb-4">
                <Trans components={{ span: <span />, a: <a /> }}>
                  {" "}
                  {t("consent.paragraph3")}
                </Trans>
              </p>
              <p className="mb-4">
                <Trans components={{ span: <span />, a: <a /> }}>
                  {t("consent.paragraph4")}
                </Trans>
              </p>
              {t("consent.paragraph5") ? (
                <p className="mb-4">
                  <Trans components={{ span: <span />, a: <a /> }}>
                    {t("consent.paragraph5")}
                  </Trans>
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="flex flex-col items-center w-full bg-white mt-4">
            {showArrow && (
              <div
                className={`flex justify-center mb-2 transition-opacity duration-500 p-2 ${
                  !showArrow ? "opacity-0" : "opacity-100"
                }`}
              >
              <Arrow />
              </div>
            )}
            {renderButton()}
           </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (data.expectedDeliveryDate == undefined) {
      return;
    }
    setFormData(data);
    const [dayBirthMother, monthBirthMother, yearBirthMother] = data.dateOfBirth
      ? data.dateOfBirth.split("/")
      : ["", "", ""];
    const dateBirthMother = new Date(
      `${yearBirthMother}-${monthBirthMother}-${dayBirthMother}`.replace(
        /-/g,
        "/"
      )
    );

    if (data.partnerDateOfBirth) {
      const [dayBirthPartner, monthBirthPartner, yearBirthPartner] =
        data.partnerDateOfBirth.split("/");
      const dateBirthPartner = new Date(
        `${yearBirthPartner}-${monthBirthPartner}-${dayBirthPartner}`.replace(
          /-/g,
          "/"
        )
      );
      setSelectedBirthDatePartner(dateBirthPartner);
    }

    const [dayDelivery, monthDelivery, yearDelivery] =
      data.expectedDeliveryDate.split("/");
    const dateDelivery = new Date(
      `${yearDelivery}-${monthDelivery}-${dayDelivery}`.replace(/-/g, "/")
    );

    setSelectedBirthDate(dateBirthMother);
    setSelectedDeliveryDate(dateDelivery);
    setIsCheckedTwins(data.havingTwins);

    // set checkboxes if they have been filled out and validated once
    if(data._formValidated) {
      setIsChecked1(true);
      setIsChecked2(true);
      setIsChecked3(true);
      setIsChecked4(true);
      setIsChecked5(true);
      
    }
  }, [data]);

  useEffect(() => {
    /*
    const newPrice = printPrice(formData.countryCode);
    onPriceChange(newPrice);
    const vatPercentage = printVATPercentage(formData.countryCode);
    const vat = printVAT(formData.countryCode);
    onVatChangePercentage(vatPercentage);
    printVAT(vat);
    onVatChange(vat);
    const premie = printPremie(formData.countryCode);
    onPremieChange(premie);
    */
  }, [formData, data.countryCode]); //, printPrice, onPriceChange]);

  useEffect(() => {
    validateInsuranceNumber();
    setFormData((prevState) => ({ ...prevState, countryCode: language }));
  }, [formData.socialSecurity, formData.countryCode]);

  useEffect(() => {
    // Update formdata each time country changes

    setFormData((prevFormData) => ({
      ...prevFormData,
      countryCode: country,
    }));
    //Update price
    /*
    const newPrice = printPrice(country);
    onPriceChange(newPrice);
    const vat = printVATPercentage(country);
    onVatChangePercentage(vat);
    const premie = printPremie(country);
    onPremieChange(premie);
    */
   updatePhoneCountryCode(country);
  }, [
    country/*,
    printPrice,
    onPriceChange,
    printVATPercentage,
    onVatChangePercentage,
    printPremie,
    onPremieChange,*/
  ]);

  return (
    <>
      <form onSubmit={handleSubmit} className="text-sm">
        <div className="mb-4">
          <label
            htmlFor="countryCode"
            className="block text-babymamGray-800 mb-2 text-[16px] md:text-[18px]"
          >
            {t("signform.resident")}
            <span className="text-babymam-600"> *</span>
          </label>
          <div className="flex">
            <label className="mr-[20px] flex justify-center justify-items-center">
              <input
                type="radio"
                name="option"
                value="yes"
                checked={isResident}
                onChange={handleResident}
                className="accent-[#BA5E98] w-5 h-5"
              />
              <span className="ml-2 text-[16px] md:text-[18px]">
                {t("signform.residentyes")}
              </span>
            </label>
            <label className="mr-[20px] flex justify-center justify-items-center">
              <input
                type="radio"
                name="option"
                value="no"
                checked={!isResident}
                onChange={handleResident}
                className="accent-[#BA5E98]  w-5 h-5"
              />
              <span className="ml-2 text-[16px] md:text-[18px]">
                {t("signform.residentno")}
              </span>
            </label>
            {showError && (
              <div className="flex center mt-2">
                <Error />{" "}
                <span className="ml-1 text-red-600">
                  {t("signform.resident_error")}
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="mb-4">
          <Input
            label={t("signform.firstname")}
            type="text"
            id="firstName"
            placeholder={t("signform.placeholdername")}
            value={formData.firstName}
            onChange={handleInputChange}
            mandatory={true}
          />
          {errors.firstName && (
            <p className="text-babymam-600">{errors.firstName}</p>
          )}
        </div>

        <div className="mb-4">
          <Input
            label={t("signform.lastname")}
            type="text"
            id="lastName"
            placeholder={t("signform.placeholderlastname")}
            value={formData.lastName}
            onChange={handleInputChange}
            mandatory={true}
          />
          {errors.lastName && (
            <p className="text-babymam-600">{errors.lastName}</p>
          )}
        </div>

        <div className="mb-4 flex flex-row">
          <div className="mr-2 w-6/12">
            <label
              htmlFor="phoneCountryCode"
              className="block text-babymamGray-800 mb-2 text-[16px] md:text-[18px]"
            >
              {t("signform.countrycodephone")}{" "}
              <span className="text-babymam-600">*</span>
            </label>
            <select
              id="phoneCountryCode"
              value={formData.phoneCountryCode}
              onChange={handleInputChange}
              required
              className="p-1.5 text-[16px] md:text-[18px] text-babymamGray-800 border border-babymamGray-300 rounded-md w-full"
            >
              <option
                value=""
                disabled
                selected
                className="text-gray-500 italic"
              >
                {t("signform.countrycodedropdown")}
              </option>
              <option value="+34">+34</option>
              <option value="+39">+39</option>
              <option value="+33">+33</option>
              <option value="+49">+49</option>
            </select>

            {errors.phoneCountryCode && (
              <p className="text-babymam-600">{errors.phoneCountryCode}</p>
            )}
          </div>
          <div className="w-6/12">
            <Input
              label={t("signform.phonenumber")}
              type="text"
              id="phoneNumber"
              placeholder={t("signform.placeholderphonenumber")}
              value={formData.phoneNumber}
              onChange={handleInputChange}
              mandatory={true}
            />
            {errors.phoneNumber && (
              <p className="text-babymam-600">{errors.phoneNumber}</p>
            )}
          </div>
        </div>

        <div className="mb-4">
          <Input
            label={t("signform.email")}
            type="email"
            id="email"
            placeholder={t("signform.placeholderemail")}
            value={formData.email}
            onChange={handleInputChange}
            mandatory={true}
          />
          {errors.email && <p className="text-babymam-600">{errors.email}</p>}
          {emailError && <p className="text-babymam-600">{emailError}</p>}
        </div>

        <div className="mb-4 flex flex-row">
          <div className="mr-[10px] w-6/12">
            <Input
              label={t("signform.street")}
              type="text"
              id="streetAddress"
              placeholder={t("signform.placeholderstreet")}
              value={formData.streetAddress}
              onChange={handleInputChange}
              mandatory={true}
            />
            {errors.streetAddress && (
              <p className="text-babymam-600">{errors.streetAddress}</p>
            )}
          </div>

          <div className=" w-6/12">
            <Input
              label={t("signform.postalcode")}
              type="text"
              id="postalCode"
              placeholder={t("signform.placeholderpostal")}
              value={formData.postalCode}
              onChange={handleInputChange}
              mandatory={true}
            />
            {errors.postalCode && (
              <p className="text-babymam-600">{errors.postalCode}</p>
            )}
          </div>
        </div>

        <div className="mb-4 flex flex-row">
          <div className="w-6/12 mr-[10px]">
            <Input
              label={t("signform.city")}
              type="text"
              id="city"
              placeholder={t("signform.placeholdercity")}
              value={formData.city}
              onChange={handleInputChange}
              mandatory={true}
            />
            {errors.city && <p className="text-babymam-600">{errors.city}</p>}
          </div>

          <div className="w-6/12">
            <Input
              label={t("signform.extaddress")}
              type="text"
              id="extField"
              placeholder={t("signform.placeholderext")}
              value={formData.extField}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {country === "IT" || country === "ES" || country === "FR" ? (
          <div className="mb-4">
            <Input
              label={translations[country]?.label}
              type="text"
              id="socialSecurity"
              placeholder={translations[country]?.placeholder}
              value={formData.socialSecurity}
              onChange={handleInputChange}
              onBlur={validateInsuranceNumber}
              mandatory={true}
            />
            {errors.socialSecurity && (
              <p className="text-babymam-600">{errors.socialSecurity}</p>
            )}

            <div className="flex flex-row text-sm bg-babymamGray-100 mt-2 p-4 rounded">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                className="mr-2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.91602 5.58342H12.0827V7.75008H9.91602V5.58342ZM9.91602 9.91675H12.0827V16.4167H9.91602V9.91675ZM10.9993 0.166748C5.01935 0.166748 0.166016 5.02008 0.166016 11.0001C0.166016 16.9801 5.01935 21.8334 10.9993 21.8334C16.9793 21.8334 21.8327 16.9801 21.8327 11.0001C21.8327 5.02008 16.9793 0.166748 10.9993 0.166748ZM10.9993 19.6667C6.22185 19.6667 2.33268 15.7776 2.33268 11.0001C2.33268 6.22258 6.22185 2.33341 10.9993 2.33341C15.7768 2.33341 19.666 6.22258 19.666 11.0001C19.666 15.7776 15.7768 19.6667 10.9993 19.6667Z"
                  fill="#BA5E98"
                />
              </svg>

              <p className="text-[14px] md:text-[16px] leading-[18px] md:leading-[24px]">
                {t("signform.socialsecurityinfo")}
              </p>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="mb-4 ">
          <DatePickerInput
            label={t("signform.dateofbirth")}
            id="dateOfBirth"
            value={selectedBirthDate}
            onChange={(date) => handleBirthDate(date)}
            mandatory={true}
            today={false}
          />
          {errors.dateOfBirth && (
            <div className="flex center mt-2">
              <Error />{" "}
              <span className="ml-1 text-red-600">{errors.dateOfBirth}</span>
            </div>
          )}
        </div>

        {country === "FR" ? (
          <div className="mb-4 flex flex-row items-end align-bottom">
            <div className="mr-2 w-6/12">
              <Input
                label={"Lieu de naissance de la mère"}
                type="text"
                id="placeOfBirthCity"
                placeholder="Ville"
                value={formData.placeOfBirthCity}
                onChange={handleInputChange}
                required={true}
                mandatory={true}
              />
              {errors.placeOfBirthCity && (
                <p className="text-babymam-600">{errors.placeOfBirthCity}</p>
              )}
            </div>

            <div className="w-6/12">
              <Input
                type="text"
                id="placeOfBirthCountry"
                placeholder="Pays *"
                value={formData.placeOfBirthCountry}
                onChange={handleInputChange}
                required={true}
                mandatory={true}
              />
              {errors.placeOfBirthCountry && (
                <p className="text-babymam-600">{errors.placeOfBirthCountry}</p>
              )}
            </div>
          </div>
        ) : null}

        <div>
          <DatePickerInput
            label={t("signform.dateexpected")}
            id="expectedDeliveryDate"
            value={selectedDeliveryDate}
            onChange={(date) => handleDeliveryDate(date)}
            mandatory={true}
          />
          {errors.expectedDeliveryDate && (
            <p className="text-babymam-600">{errors.expectedDeliveryDate}</p>
          )}

          <div className="flex flex-row text-sm bg-babymamGray-100 mt-2 p-4 rounded">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              className="mr-2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.91602 5.58342H12.0827V7.75008H9.91602V5.58342ZM9.91602 9.91675H12.0827V16.4167H9.91602V9.91675ZM10.9993 0.166748C5.01935 0.166748 0.166016 5.02008 0.166016 11.0001C0.166016 16.9801 5.01935 21.8334 10.9993 21.8334C16.9793 21.8334 21.8327 16.9801 21.8327 11.0001C21.8327 5.02008 16.9793 0.166748 10.9993 0.166748ZM10.9993 19.6667C6.22185 19.6667 2.33268 15.7776 2.33268 11.0001C2.33268 6.22258 6.22185 2.33341 10.9993 2.33341C15.7768 2.33341 19.666 6.22258 19.666 11.0001C19.666 15.7776 15.7768 19.6667 10.9993 19.6667Z"
                fill="#BA5E98"
              />
            </svg>

            <p className="text-[14px] md:text-[16px] leading-[18px] md:leading-[24px]">
              {t("signform.expectedinfo")}
            </p>
          </div>
        </div>

        <div className="flex flex-col pt-5 pb-5">
          <label
            htmlFor="havingTwins"
            className="text-babymamGray-800  text-[16px] md:text-[18px]"
          >
            {" "}
            {t("signform.twins")}
          </label>
          <div className="mt-[10px] flex">
            <label className="flex justify-center justify-items-center">
              <input
                type="radio"
                name="twins"
                value="true"
                checked={formData.havingTwins === true}
                onChange={handleCheckboxTwinsChange}
                className="accent-[#BA5E98] w-5 h-5"
              />
              <span className="ml-2 text-[16px] md:text-[18px]">
                {t("signform.twinsyes")}
              </span>
            </label>

            <label className="flex justify-center justify-items-center">
              <input
                type="radio"
                name="twins"
                value="false"
                checked={formData.havingTwins === false}
                onChange={handleCheckboxTwinsChange}
                className="ml-[20px] accent-[#BA5E98] w-5 h-5"
              />
              <span className="ml-2 text-[16px] md:text-[18px]">
                {t("signform.twinsno")}
              </span>
            </label>
          </div>

          {formData.havingTwins && (
            <div className="mt-4 flex flex-col">
              <label
                htmlFor="childCount"
                className="text-babymamGray-800 text-[16px] md:text-[18px]"
              >
                {t("signform.twinsnumber")}
              </label>
              <Input
                type="number"
                id="childCount"
                name="childCount"
                value={formData.childCount}
                onChange={handleChildCountChange}
                className="mt-2 w-full"
              />
            </div>
          )}

          <div className="flex flex-row text-sm bg-babymamGray-100 mt-2 p-4 rounded">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              className="mr-2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.91602 5.58342H12.0827V7.75008H9.91602V5.58342ZM9.91602 9.91675H12.0827V16.4167H9.91602V9.91675ZM10.9993 0.166748C5.01935 0.166748 0.166016 5.02008 0.166016 11.0001C0.166016 16.9801 5.01935 21.8334 10.9993 21.8334C16.9793 21.8334 21.8327 16.9801 21.8327 11.0001C21.8327 5.02008 16.9793 0.166748 10.9993 0.166748ZM10.9993 19.6667C6.22185 19.6667 2.33268 15.7776 2.33268 11.0001C2.33268 6.22258 6.22185 2.33341 10.9993 2.33341C15.7768 2.33341 19.666 6.22258 19.666 11.0001C19.666 15.7776 15.7768 19.6667 10.9993 19.6667Z"
                fill="#BA5E98"
              />
            </svg>

            <p className="text-[14px] md:text-[16px] leading-[18px] md:leading-[24px]">
              {t("signform.twinsinfo")}
            </p>
          </div>
        </div>

        <div className="mb-4">
          <Input
            label={t("signform.partnername")}
            type="text"
            id="partnerName"
            placeholder={t("signform.placeholderpartnername")}
            value={formData.partnerName}
            onChange={handleInputChange}
            mandatory={false}
          />
        </div>

        <div className="mb-4">
          <DatePickerInput
            label={t("signform.datepartner")}
            id="dateOfBirthPartner"
            value={selectedBirthDatePartner}
            onChange={(date) => handleBirthDatePartner(date)}
            mandatory={false}
            today={false}
          />
          {/* {errors.partnerDateOfBirth && (
            <p className="text-babymam-600">{errors.partnerDateOfBirth}</p>
          )} */}
        </div>

        <div className="mt-[40px]">
          <span className="text-[14px]  leading-[18px]">
            {t("signform.confirm")}
          </span>

          <div className="flex  pt-[10px] pb-[5px]">
            <Checkbox
              label={checkLabel1}
              checked={isChecked1}
              onChange={() => handleCheckboxChange(1)}
              id="checkbox1"
            />
          </div>
          <div className="flex pt-[5px] pb-[5px]">
            <Checkbox
              label={checkLabel2}
              checked={isChecked2}
              onChange={() => handleCheckboxChange(2)}
              id="checkbox2"
            />
          </div>
          <div className="flex pt-[5px] pb-[5px]">
            <Checkbox
              label={checkLabel3}
              checked={isChecked3}
              onChange={() => handleCheckboxChange(3)}
              id="checkbox3"
            />
          </div>

          {useChecklabel4 && (
            <div className="flex pt-[5px] pb-[5px]">
            <Checkbox
              label={checkLabel4}
              checked={isChecked4}
              onChange={() => handleCheckboxChange(4)}
              id="checkbox4"
            />
          </div>
          )}
                    
          <div className="flex pt-[5px] pb-[5px]">
            <Checkbox
              label={checkLabel5}
              checked={isChecked5}
              onChange={() => handleCheckboxChange(5)}
              id="checkbox5"
            />
          </div>
        </div>
        <div style={{ display: showConsentPopup ? "block" : "none" }}>
          <ConsentPopup
            onClose={handleCloseConsent}
            onAccept={handleAcceptConsent}
            isConsentButtonEnabled={isConsentButtonEnabled}
            setIsConsentButtonEnabled={setIsConsentButtonEnabled}
            showArrow={showArrow}
            setShowArrow={setShowArrow}
            consentScrollPos={consentScrollPos}
            setConsentScrollPos={setConsentScrollPos}
          />
        </div>

        <div className="mb-[20px]">
          <span>
            <Trans components={defaultTransformComponents} >
              {t("signform.insurance")}
            </Trans>
          </span>
          {!(isChecked1 && isChecked2 && isChecked3 && isChecked4 && isChecked5) ? (
            <div className="flex center mt-2">
              <Error />{" "}
              <span className="ml-1 text-red-600">
                {t("signform.confirmerror")}
              </span>
            </div>
          ) : (
            ""
          )}
        </div>

        <Button
          desk="20"
          mobile="20"
          disabled={isButtonDisabled}
          onClick={handleSubmit}
          className="p-5 md:p-0"
        >
          {t("signform.next")}
        </Button>
      </form>
    </>
  );
};

export default SignForm;
