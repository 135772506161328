import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as MenuIcon } from "../images/menu-icon.svg";
import { ReactComponent as LogoMobile } from "../images/babymam-logo-mobile.svg";
import { ReactComponent as LogoDesktop } from "../images/babymam-logo-desktop.svg";
import InternalLink from "../components/InternalLink";

import {
  Link,
  NavLink,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import Button from "./Button";
import { useLanguage } from "../contexts/LanguageContext";
import i18n from "../i18n";

const CombinedHeader = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const { country, setCountry } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpened, setIsMenuOpenend] = useState(false);
  const [isCountrySelectorOpen, setIsCountrySelectorOpen] = useState(false);
  const dropdownRef = useRef(null);

  const { t, i18n  } = useTranslation();
  const location = useLocation();
  const { languageCode } = useParams();
  const navigate = useNavigate();

  //const country = i18n.language;

  const pathWithoutCountry = location.pathname.replace(/^\/[^/]+/, "");

  //const hideSection = pathWithoutCountry === "/product/checkout" || country === "EN";
  const hideSection  = true
  
  const handleResize = () => {
    const screenWidth = window.innerWidth;
    setIsMobile(screenWidth <= 767);
    setIsTablet(screenWidth >= 768 && screenWidth <= 1023);
    setIsDesktop(screenWidth >= 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const items = [
    { name: t("header.our_insurance"), link: "/our_insurance" },
    { name: t("header.benefits"), link: "/product" },
    //{ name: t("header.our_story"), link: "/about" }
  ];

  const [selectedCountry, setSelectedCountry] = useState(country);

  const countries = [
    { code: "DE", name: "Germany", path: "/de/our_insurance" },
    { code: "IT", name: "Italy", path: "/it/our_insurance" },
    { code: "FR", name: "France", path: "/fr/our_insurance" },
    { code: "ES", name: "Spain", path: "/es/our_insurance" },
    { code: "EN", name: "Other", path: "/en" },
  ];

  const filteredItems = items.filter((item) => {
    if (
      (item.name === "Benefits" && country === "EN") ||
      (item.name === "Our Insurance" && country === "EN")
    ) {
      return false;
    }
    return true;
  });

  const toggleCountryDropdown = () =>
    setIsCountrySelectorOpen(!isCountrySelectorOpen);

  const toggleMenu = () =>
    setIsMenuOpenend(!isMenuOpened)

  const closeMenu = () => {
    setIsCountrySelectorOpen(false);
    setIsMenuOpenend(false)
  }
  
  const handleCountrySelect = (code) => {
    setSelectedCountry(code);
    changeLanguage(code);
    closeMenu();
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language?.toLowerCase());
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isCountrySelectorOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setTimeout(() => {
          setIsCountrySelectorOpen(false);
        }, 100);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isCountrySelectorOpen]);

  
  useEffect(() => {
    setSelectedCountry(country);
    changeLanguage(country);
  }, [country]);
 

  return (
    <>
      {isMobile ? (
        // MOBILE MENU
        <header>

          <div className="max-w-full flex flex-row px-[20px] py-[10px] sticky top-0 bg-white justify-between z-[100]">
            
            {isMenuOpened && (
            <div
              className="absolute left-4 top-0 mt-[60px] bg-white border rounded shadow-lg z-[200] "
              ref={dropdownRef}
              onClick={() => setIsMenuOpenend(false)}
              >
              {filteredItems?.map((item, i) => (
                <div className="p-3">

                <InternalLink
                  to={item?.link}
                  key={i}
                  className={({ isActive }) =>
                    `${
                      isActive && !hideSection
                        ? "border-l-[3px] border-babymam-600"
                        : ""
                    } p-3 hover:bg-babymam-100 cursor-pointer text-left text-[16px] text-babymam-1000 font-semibold]`
                  }
                >
                {item.name}
                </InternalLink></div>
              ))}
            </div>
            )}

            {isCountrySelectorOpen && (
            <div
              className="absolute right-4 top-0 justify-end mt-[60px] w-[190px] bg-white border rounded shadow-lg p-[10px] z-[200] "
              ref={dropdownRef}
              >
              {countries.map((country) => (
                <InternalLink to={country.path}>     
                <div
                  key={country.code}
                  className="p-3 hover:bg-babymam-100 cursor-pointer text-left text-[16px] text-babymam-1000 font-semibold"
                  onClick={() => handleCountrySelect(country.code)}
                >
                {country.name} ({country.code})
                </div>
                </InternalLink>
              ))}
            </div>
            )}

            <div className="flex flex-row justify-start items-center text-[14px] gap-[8px] w-[52px] ">
              <div
                className={`${
                  isCountrySelectorOpen ? " bg-babymam-100" : ""
                } flex flex-row rounded-[4px] hover:cursor-pointer`}
                onClick={toggleMenu}
                >
                <div className="p-1">
                  <MenuIcon width="20" height="20" viewBox="0 0 21 21" />
                </div>
              </div>              
            </div>

            <div className="flex flex-row">
              <div className="absolute flex flex-row justify-center items-center ">
                <InternalLink to="/">
                  <LogoMobile />
                </InternalLink>
              </div>
            </div>

            <div className="flex flex-row justify-end items-center text-[14px] gap-[8px] w-[212px] ">
              <div
                className={`${
                  isCountrySelectorOpen ? " bg-babymam-100" : ""
                } flex flex-row rounded-[4px] hover:cursor-pointer`}
                onClick={toggleCountryDropdown}
              >
                <div className="p-1">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2903 0.5C4.77029 0.5 0.300293 4.98 0.300293 10.5C0.300293 16.02 4.77029 20.5 10.2903 20.5C15.8203 20.5 20.3003 16.02 20.3003 10.5C20.3003 4.98 15.8203 0.5 10.2903 0.5ZM17.2203 6.5H14.2703C13.9503 5.25 13.4903 4.05 12.8903 2.94C14.7303 3.57 16.2603 4.85 17.2203 6.5ZM10.3003 2.54C11.1303 3.74 11.7803 5.07 12.2103 6.5H8.39029C8.82029 5.07 9.47029 3.74 10.3003 2.54ZM2.56029 12.5C2.40029 11.86 2.30029 11.19 2.30029 10.5C2.30029 9.81 2.40029 9.14 2.56029 8.5H5.94029C5.86029 9.16 5.80029 9.82 5.80029 10.5C5.80029 11.18 5.86029 11.84 5.94029 12.5H2.56029ZM3.38029 14.5H6.33029C6.65029 15.75 7.11029 16.95 7.71029 18.06C5.87029 17.43 4.34029 16.16 3.38029 14.5ZM6.33029 6.5H3.38029C4.34029 4.84 5.87029 3.57 7.71029 2.94C7.11029 4.05 6.65029 5.25 6.33029 6.5ZM10.3003 18.46C9.47029 17.26 8.82029 15.93 8.39029 14.5H12.2103C11.7803 15.93 11.1303 17.26 10.3003 18.46ZM12.6403 12.5H7.96029C7.87029 11.84 7.80029 11.18 7.80029 10.5C7.80029 9.82 7.87029 9.15 7.96029 8.5H12.6403C12.7303 9.15 12.8003 9.82 12.8003 10.5C12.8003 11.18 12.7303 11.84 12.6403 12.5ZM12.8903 18.06C13.4903 16.95 13.9503 15.75 14.2703 14.5H17.2203C16.2603 16.15 14.7303 17.43 12.8903 18.06ZM14.6603 12.5C14.7403 11.84 14.8003 11.18 14.8003 10.5C14.8003 9.82 14.7403 9.16 14.6603 8.5H18.0403C18.2003 9.14 18.3003 9.81 18.3003 10.5C18.3003 11.19 18.2003 11.86 18.0403 12.5H14.6603Z"
                      fill="#6B1D66"
                    />
                  </svg>
                </div>
                <div className="p-1 text-babymam-900 font-semibold flex items-center">
                  {selectedCountry}
                </div>
                <button className="p-1">
                  <svg
                    width="13"
                    height="9"
                    viewBox="0 0 13 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`${
                      isCountrySelectorOpen ? "transform rotate-180" : ""
                    }`}
                  >
                    <path
                      d="M10.8903 0.794922L6.30029 5.37492L1.71029 0.794922L0.300293 2.20492L6.30029 8.20492L12.3003 2.20492L10.8903 0.794922Z"
                      fill="#6B1D66"
                    />
                  </svg>
                </button>
              </div>
            
            </div>
          </div>
                    
        </header>
      ) : (
        // DESKTOP MENU
        <header className="hidden md:block sticky top-0 flex-row items-center justify-between bg-white z-[100] shadow-md ">
          <div className="flex px-10 py-[7.5px] font-semibold justify-between items-center text-babymam-1000 ">
            <InternalLink to="/" className="flex items-center align-text-bottom ">
              <LogoDesktop />
            </InternalLink>
            <div>
              {filteredItems?.map((item, i) => (
                <InternalLink to={item.link}
                  key={i}
                  className={({ isActive }) =>
                    `${
                      isActive //&& !hideSection
                        ? "border-b-[3px] border-babymam-600"
                        : ""
                    } px-5 py-4  w-[7.4rem] text-center text-[20px]`
                  }
                >
                  {item.name}
                </InternalLink>
              ))}
            </div>
            <div className="flex flex-row justify-end items-center text-[14px] gap-[8px] ">
              <div className="w-full p-1 text-[14px] text-babymam-900">
              {t("header.location")}
              </div>
              <div
                className={`${
                  isCountrySelectorOpen ? " bg-babymam-100" : ""
                } flex flex-row rounded-[4px] hover:cursor-pointer`}
                onClick={toggleCountryDropdown}
              >
                <div className="p-1">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2903 0.5C4.77029 0.5 0.300293 4.98 0.300293 10.5C0.300293 16.02 4.77029 20.5 10.2903 20.5C15.8203 20.5 20.3003 16.02 20.3003 10.5C20.3003 4.98 15.8203 0.5 10.2903 0.5ZM17.2203 6.5H14.2703C13.9503 5.25 13.4903 4.05 12.8903 2.94C14.7303 3.57 16.2603 4.85 17.2203 6.5ZM10.3003 2.54C11.1303 3.74 11.7803 5.07 12.2103 6.5H8.39029C8.82029 5.07 9.47029 3.74 10.3003 2.54ZM2.56029 12.5C2.40029 11.86 2.30029 11.19 2.30029 10.5C2.30029 9.81 2.40029 9.14 2.56029 8.5H5.94029C5.86029 9.16 5.80029 9.82 5.80029 10.5C5.80029 11.18 5.86029 11.84 5.94029 12.5H2.56029ZM3.38029 14.5H6.33029C6.65029 15.75 7.11029 16.95 7.71029 18.06C5.87029 17.43 4.34029 16.16 3.38029 14.5ZM6.33029 6.5H3.38029C4.34029 4.84 5.87029 3.57 7.71029 2.94C7.11029 4.05 6.65029 5.25 6.33029 6.5ZM10.3003 18.46C9.47029 17.26 8.82029 15.93 8.39029 14.5H12.2103C11.7803 15.93 11.1303 17.26 10.3003 18.46ZM12.6403 12.5H7.96029C7.87029 11.84 7.80029 11.18 7.80029 10.5C7.80029 9.82 7.87029 9.15 7.96029 8.5H12.6403C12.7303 9.15 12.8003 9.82 12.8003 10.5C12.8003 11.18 12.7303 11.84 12.6403 12.5ZM12.8903 18.06C13.4903 16.95 13.9503 15.75 14.2703 14.5H17.2203C16.2603 16.15 14.7303 17.43 12.8903 18.06ZM14.6603 12.5C14.7403 11.84 14.8003 11.18 14.8003 10.5C14.8003 9.82 14.7403 9.16 14.6603 8.5H18.0403C18.2003 9.14 18.3003 9.81 18.3003 10.5C18.3003 11.19 18.2003 11.86 18.0403 12.5H14.6603Z"
                      fill="#6B1D66"
                    />
                  </svg>
                </div>
                <div className="p-1 text-babymam-900 font-semibold flex items-center">
                  {selectedCountry}
                </div>
                <button className="p-1 ">
                  <svg
                    width="13"
                    height="9"
                    viewBox="0 0 13 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`${
                      isCountrySelectorOpen ? "transform rotate-180" : ""
                    }`}
                  >
                    <path
                      d="M10.8903 0.794922L6.30029 5.37492L1.71029 0.794922L0.300293 2.20492L6.30029 8.20492L12.3003 2.20492L10.8903 0.794922Z"
                      fill="#6B1D66"
                    />
                  </svg>
                </button>
              </div>
              {isCountrySelectorOpen && (
                <div
                  className="absolute mt-[290px] w-[190px] bg-white border rounded shadow-lg p-[10px] "
                  ref={dropdownRef}
                >
                  {countries.map((country) => (
                    <InternalLink to={country.path}>     
                      <div key={country.code} 
                        className="p-3 hover:bg-babymam-100 cursor-pointer"
                        onClick={() => handleCountrySelect(country.code)}>
                        {country.name} ({country.code.toUpperCase()})
                      </div>
                    </InternalLink>
                  ))}
                </div>
              )}
            </div>
          </div>

          
          {!hideSection && (
            <div className="p-2.5 flex justify-center items-center bg-babymam-200 text-babymam-600 shadow-md text-sm hidden md:flex">
              <span className="mr-6 text-babymam-900 text-[18px]">
              {t("header.started")}
              </span>
              <InternalLink to="/product" >
                <Button id="started"  desk="16">{t("started")}</Button>
              </InternalLink>
            </div>
          )}
        </header>
      )}
    </>
  );
};

export default CombinedHeader;
