import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const InternalLink = ({ to, className, children }) => {
  const { i18n } = useTranslation();
  const language = i18n.language;

  // Regular expression to check if the path starts with a language code
  const startsWithLanguageCode = /^\/(en|it|es|de|fr)/.test(to);

  return (
    <NavLink to={startsWithLanguageCode ? to : `/${language}${to}`} className={className}>
      {children}
    </NavLink>
  );
};

export default InternalLink;