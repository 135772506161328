/* global fbq */

import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import NewStepper from "../components/NewStepper";
import PaymentForm from "../components/PaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import apiFactory from "../helpers/apiFactory";
import { Elements } from "@stripe/react-stripe-js";
import { useLanguage } from "../contexts/LanguageContext";
import Spinner from "../components/Spinner";
import InsuranceHelper from "../helpers/InsuranceHelper";

const Payment = () => {
  const { t } = useTranslation();
  const { country } = useLanguage();
  const [key, setKey] = useState(null);
  const [options, setOptions] = useState({});
  const [stripePromise, setStripePromise] = useState("");
  const location = useLocation();
  const plan = location.state?.plan;
  const navigate = useNavigate();
  const [title, setTitle] = useState("checkout.payment");
  const [subtitle, setSubtitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (!country) return;
    const insuranceForm = sessionStorage.getItem("insuranceForm");
    if (insuranceForm) {
      const formData = JSON.parse(insuranceForm);
      console.log("Payment, loaded data:", formData); // Confirm correct data loading
      startPayment(formData);
    } else {
      const code = country.toLowerCase();
      console.log("code: ", code);
      navigate(`/${code}/product/checkout`);
    }
  }, [country, navigate]);

  const startPayment = async (formData) => {
    setIsLoading(true);
    const api = apiFactory();

    try {
      console.log("createInsurance data:",formData);
    
      const res = await api.createInsurance(formData);
      setKey(res.paymentReference);
      setOptions({
        clientSecret: res.paymentReference,
      });
      //setExternalID(res.externalID);
      setStripePromise(loadStripe(res.paymentKey));
      
      api.getInsurance(res.externalID)
        .then((response) => {
          console.log("getInsurance :: ", JSON.stringify(response));
          console.log("getInsurance :: dateOfPurchase ", response.dateOfPurchase);
          setInsuranceDetails(response);
          //setIsPayment(true);
          //setIsFormDetails(false);
          setTitle("checkout.payment");
          setSubtitle("");
          setIsLoading(false);

          //store response for purhcase step
          sessionStorage.setItem('insuranceData', JSON.stringify(response));
        });
       
    } catch (error) {
      console.error("Error API:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmation = () => {
    const code = country.toLowerCase();
    navigate(`/${code}/product/purchase`);
        
    //meta tracking
    fbq('track', 'Purchase', {value: InsuranceHelper.getTotal(), currency: 'EUR'});
  };

  const [currentStep, setCurrentStep] = useState(3);
  const stepArray = [
    t("checkoutstepper.step1"),
    t("checkoutstepper.step2"),
    t("checkoutstepper.step3"),
    t("checkoutstepper.step4"),
  ];

  const [insuranceDetails, setInsuranceDetails] = useState("");
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isLoading]);

  const textPlan =
    plan === "basic" ? "Basic plan" : t("confirmationsummary.insurance");

  return (
    <>
      <div className="w-full md:px-[60px] justify-center justify-items-center ">
        <div className="md:mb-20 md:px-20 max-w-[1728px] mx-auto">
          <NewStepper steps={stepArray} currentStepNumber={currentStep} />
        </div>
      </div>

        <div className="w-full p-5 md:p-10 bg-white md:bg-babymamGray-100 justify-center justify-items-center">
          <div className="justify-center text-center pb-5 md:pb-10 max-w-[1728px] mx-auto">
            <h2 className="headline-big">
              {t(title)}
            </h2>
            <p className="mt-4 text-[18px] md:text-[20px]">{t(subtitle)}</p>

            {key != null && (
              <div className="md:mx-auto md:w-6/12 justify-center text-center">
                {/* <div>
                <h2 className="text-4xl font-bold font-serif text-babymam-900 text-[34px] md:text-[48px]">
                  Payment
                </h2>
              </div> */}
                <div
                  className={`${
                    plan === "basic" ? "bg-babymamGray-200" : "bg-babymam-100"
                  } mt-10 rounded-lg px-10 py-4`}
                >
                  <div className="border-b-2 border-babymam-400 pb-4 font-semibold text-babymam-900 text-[18px] md:text-[28px]">
                    {t("confirmationsummary.summary")}
                  </div>
                  <div className="py-4 flex flex-col text-babymam-900 text-[18px] md:text-[20px]">
                    <div className="flex flex-row justify-between">
                      <span className="text-left">
                        {t("confirmationsummary.chosen")}{" "}
                      </span>
                      <span className="font-semibold text-right">
                        {textPlan}
                      </span>
                    </div>
                    <div className="flex flex-row justify-between mt-5">
                      <span className="text-[14px] leading-[18px]">
                        {t("confirmationsummary.premie")}{" "}
                      </span>
                      <span className="text-[14px] leading-[18px]">
                        {InsuranceHelper.getPremium() } €
                      </span>
                    </div>
                    {country === "ES" ? (
                      <>
                        <div className="flex flex-row justify-between mt-[5px]">
                          <span className="text-[14px] md:text-[16px]  leading-[18px]">
                            Consorcio:{" "}
                          </span>
                          <span className="text-[14px] md:text-[16px] leading-[18px]">
                            { InsuranceHelper.getVATExt() } €
                          </span>
                        </div>
                        <div className="flex flex-row justify-between mt-[5px]">
                          <span className="text-[14px] md:text-[16px]  leading-[18px]">
                            L.E.A:{" "}
                          </span>
                          <span className="text-[14px] md:text-[16px] leading-[18px]">
                          { InsuranceHelper.getVATExt2() } €
                          </span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="flex flex-row justify-between">
                      <span className="text-[14px] leading-[18px]">
                        {t("confirmationsummary.vat")}{" "}
                      </span>
                      <span className="text-[14px] leading-[18px]">
                        {InsuranceHelper.getVAT()} €
                      </span>
                    </div>
                    <div className="mt-4 flex flex-row justify-between">
                      <span>{t("confirmationsummary.total")} </span>
                      <span className="font-semibold">{InsuranceHelper.getTotal()} €</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className="flex-col-reverse items-center flex md:px-10 justify-center  max-w-[1728px] mx-auto">
            {isLoading ? (
              <Spinner />
            ) : (
              <div className=" bg-white rounded-lg p-5 md:p-10 mb-6 md:w-6/12 ">
                {key != null && (
                  <Elements stripe={stripePromise} options={options}>
                    <PaymentForm handleConfirmation={handleConfirmation} />
                  </Elements>
                )}
              </div>
            )}
          </div>
        </div>
      
    </>
  );
};

export default Payment;
