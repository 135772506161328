/* global fbq */

import React, { useEffect, useState } from "react";

import { useTranslation, Trans } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import NewStepper from "../components/NewStepper";
import DetailsForm from "../components/DetailsForm";
import { useLanguage } from "../contexts/LanguageContext";

const CheckoutSummary = () => {
  const { t } = useTranslation();
  const { country, setCountry } = useLanguage();
  const [data, setData] = useState();
  const location = useLocation();
  const plan = location.state?.plan;

  const navigate = useNavigate();
  const [title, setTitle] = useState("checkout.correct");
  const [subtitle, setSubtitle] = useState("");

  useEffect(() => {
    if (!country) return;

    const insuranceForm = sessionStorage.getItem("insuranceForm");
    if (insuranceForm) {
      const parsedData = JSON.parse(insuranceForm);
      console.log("summary, loaded data:", parsedData); // Confirm correct data loading
      setData(parsedData);
    } else {
      const code = country.toLowerCase();
      console.log("code: ", code);
      navigate(`/${code}/product/checkout`);
    }
  }, [country, navigate]);

  const handleEdit = (data) => {
    console.log("handleEdit", data);
    navigate(-1);
  };

  const [currentStep, setCurrentStep] = useState(2);
  const stepArray = [
    t("checkoutstepper.step1"),
    t("checkoutstepper.step2"),
    t("checkoutstepper.step3"),
    t("checkoutstepper.step4"),
  ];

  const handlePayment = async () => {
    const code = country.toLowerCase();
    navigate(`/${code}/product/payment`);
  };

  return (
    <>
      <div className="w-full md:px-[60px] justify-center justify-items-center">
        <div className="md:mb-20 md:px-20 max-w-[1728px] mx-auto">
          <NewStepper steps={stepArray} currentStepNumber={currentStep} />
        </div>
      </div>
  
      <div className="w-full p-5 md:p-10 bg-white md:bg-babymamGray-100 justify-center justify-items-center">
        <div className="justify-center text-center pb-5 md:pb-10 max-w-[1728px] mx-auto">
          <h2 className="headline-big">
            {t(title)}
          </h2>
          <p className="mt-4 text-[18px] md:text-[20px]">{t(subtitle)}</p>
        </div>
  
        <div className="flex-col md:flex-row flex md:px-10 justify-center max-w-[1728px] mx-auto">
          <div className="bg-white rounded-lg p-5 md:p-10 mb-6 md:w-6/12">
            {/* Render DetailsForm only when data is populated */}
            {data ? (
              <DetailsForm
                form={data}
                plan={plan}
                handlePayment={handlePayment}
                handleEdit={handleEdit}
              />
            ) : (
              <p>Loading...</p> // Optional loading indicator or message
            )}
          </div>
        </div>
      </div>
    </>
  );

};

export default CheckoutSummary;
