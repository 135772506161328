import React, {useState} from "react";
import PhoneSection from "./sections/PhoneSection";
import { NavLink } from "react-router-dom";
import Button from "../components/Button";
import { useTranslation } from "react-i18next";
import FAQList from "../components/FAQComp";
import { useLanguage } from "../contexts/LanguageContext";
import InternalLink from "../components/InternalLink";

const Cookies = () => {
  const { t } = useTranslation();
  const cookieRows = t('cookies.rows', { returnObjects: true }) || [];
  const [openAccordion, setOpenAccordion] = useState(null);
  const { country } = useLanguage();

  return (
    <>
      <div className="w-full p-5 md:pt-20 md:px-48 justify-center justify-items-center">
        <div className="w-full justify-center text-center">
          <div className="md:mx-auto">
            <h1 className="text-[28px] md:text-[36px] font-bold font-serif text-babymam-900">
              {t("cookies.title")}
            </h1>
          </div>
        </div>
      </div>

      <div className="w-full p-5 md:p-20 flex justify-center justify-items-center">
        <div className="w-full md:max-w-[1000px]">
          <FAQList data={cookieRows} openAccordion={openAccordion} setOpenAccordion={setOpenAccordion}/>
        </div>
      </div>

              
      {/* FIND */}
      {country !== "EN" && (
        <>
        <div className="w-full p-5 md:px-48 md:py-16 bg-leafs bg-cover justify-center justify-items-center">
          <div className="w-full justify-center text-center">
            <div className="md:w-6/12 rounded mx-auto p-10">
              <h2 className="text-[26px] md:text-[28px] font-bold text-babymam-900 mb-6">
              {t("cookies.banner_title")}
              </h2>
              <NavLink to="/contact">
                <Button>{t("cookies.banner_button")}</Button>
              </NavLink>
            </div>
          </div>
        </div>
        </>
      )}

      

      {/* PHONES */}
      <PhoneSection faq={true} customImage={true}/>
    </>
  );
};

export default Cookies;
