/* global fbq */

import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import NewStepper from "../components/NewStepper";
import { useLanguage } from "../contexts/LanguageContext";

const Purchased = () => {
  const { t } = useTranslation();
  const { country } = useLanguage();
  const location = useLocation();
  const plan = location.state?.plan;

  useEffect(() => {
    //Wait until `country` is populated
    if (!country) return;

    // flush form data
    sessionStorage.removeItem("insuranceForm")

    // Retrieve the JSON string from sessionStorage and parse it back to an object
    const insuranceData = sessionStorage.getItem('insuranceData');
    if (insuranceData) {
      const response = JSON.parse(insuranceData)
      setPolicyNumber(response.policyNumber);
      setPurchaseDate(formatDate(response.dateOfPurchase));
      setCoverageEndDate(formatDate(response.coverageEndDate));
      setValidFromDate(formatDate(response.validFromDate));
      setExpectedDeliveryDate(formatDate(response.expectedDeliveryDate));
      setDocumentsList(response.documentList);
    } else {
      const code = country.toLowerCase();
      navigate(`/${code}/product/checkout`);
    }
  }, [country]);

  const navigate = useNavigate();
  const [policyNumber, setPolicyNumber] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [coverageEndDate, setCoverageEndDate] = useState("");
  const [validFromDate, setValidFromDate] = useState("");
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState("");
  const [documentList, setDocumentsList] = useState([]);

  
  const steps = [
    t("confirmationsteps.step1"),
    t("confirmationsteps.step2"),
    t("confirmationsteps.step3"),
    t("confirmationsteps.step4"),
  ];
  
  const [currentStep, setCurrentStep] = useState(4);
  const stepArray = [
    t("checkoutstepper.step1"),
    t("checkoutstepper.step2"),
    t("checkoutstepper.step3"),
    t("checkoutstepper.step4"),
  ];

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    
    switch (country) {
      case "DE":
        return new Intl.DateTimeFormat('de-DE').format(date);
      default:
        return `${day}/${month}/${year}`;
    }    
  };

  return (
    <>
      <div className="w-full md:px-[60px] justify-center justify-items-center ">
        <div className="md:mb-20 md:px-20 max-w-[1728px] mx-auto">
          <NewStepper steps={stepArray} currentStepNumber={currentStep} />
        </div>        

        
        <div className="p-5 md:mx-auto md:w-6/12 justify-center text-center max-w-[1728px] mx-auto ">
        <div className="max-w-[1728px] mx-auto">
            <p className="text-[16px] md:text-[18px] text-babymam-900 font-semibold mb-4">
            {t("confirmation.title")}
            </p>
            <h2 className="text-[26px] md:text-[36px] font-bold font-serif text-babymam-900">
            {t("confirmation.subtitle")}
            </h2>
            <p className="mt-4 text-[18px] md:text-[20px]">
            {t("confirmation.text")}
            </p>
        </div>
        <div
            className={`${
            plan === "basic" ? "bg-babymamGray-200" : "bg-babymam-100"
            } mt-10 rounded-lg px-10 py-4`}
        >
            <div className="border-b-2 border-babymam-400 pb-4 font-semibold text-babymam-900 text-[18px] md:text-[28px]">
            {t("confirmation.pregnancy")}
            </div>
            <div className="py-4 flex flex-col text-babymam-900 text-[18px] md:text-[20px]">
            <div className="flex flex-row justify-between">
                <span className="text-[16px] md:text-[20px] text-left">
                {t("confirmation.policynumber")}{" "}
                </span>
                <span className="font-semibold text-right">
                {policyNumber}
                </span>
            </div>
            <div className="mt-4 flex flex-row justify-between">
                <span className="text-[16px] md:text-[20px] text-left">
                {t("confirmation.date")}{" "}
                </span>
                <span className="font-semibold text-right">{purchaseDate}</span>
            </div>
            <div className="mt-4 flex flex-row justify-between">
                <span className="text-[16px] md:text-[20px] text-left">
                {t("confirmation.expected")}{" "}
                </span>
                <span className="font-semibold text-right">
                {expectedDeliveryDate}
                </span>
            </div>
            {country === "FR" ? ( // Variant for France
            <div className="mt-4 flex flex-col justify-between">
                <span className="text-[16px] md:text-[20px] text-left w-full">
                {t("confirmation.policystart")}{" "}
                </span>
            </div>
            ) : (  // Variant for other countries
            <div className="mt-4 flex flex-row justify-between">
                <span className="text-[16px] md:text-[20px] text-left">
                {t("confirmation.policystart")}{" "}
                </span>
                <span className="font-semibold text-right">
                {validFromDate}
                </span>
            </div>
            )}
            <div className="mt-4 flex flex-row justify-between">
                <span className="text-[16px] md:text-[20px] text-left">
                {t("confirmation.policyend")}{" "}
                </span>
                <span className="font-semibold text-right">
                {coverageEndDate}
                </span>
            </div>
            </div>
        </div>

        <div className="bg-[#FFFFFF] mt-[40px] md:mt-[70px] relative">
            <h3 className="text-[22px] font-semibold text-left mb-5">
            {t("confirmation.happen")}
            </h3>

            <div>
            {steps.map((step, index) => (
                <div key={index} className="relative flex items-start">
                <div className="mr-2">
                    <div className="w-8 h-8 rounded-full bg-babymam-600 flex items-center justify-center text-white text-[16px] leading-[20px] md:leading-[30px] md:text-[18px]">
                    {index + 1}
                    </div>
                    {index < steps.length - 1 && (
                    <div className="absolute left-[15px] top-8 bottom-0 w-0.5 bg-gray-300  "></div>
                    )}
                </div>

                <p className="mb-5 text-start text-[16px] leading-[20px] md:leading-[30px] md:text-[18px]">
                    <Trans components={{ a: <a />, span: <span /> }}>
                    {step}
                    </Trans>
                </p>
                </div>
            ))}
            </div>
        </div>

        <div className="bg-[#FFFFFF] mt-[40px] relative">
            <h3 className="text-[22px] font-semibold text-left mb-5">
            {t("confirmation.information")}
            </h3>

            <div className="flex flex-wrap justify-start mt-[30px] px-1 w-full gap-[20px] max-w-[738px]">

            {documentList.map((document, index) => (
                <a
                key={index}  // Add a key for each item in the list
                className="flex items-start bg-white p-5 w-full shadow-[0_4px_10px_0px_rgba(0,0,0,0.12)] rounded-[10px]"
                href={document.documentURL}
                target="_blank"
                >
                <div className="flex-shrink-0">
                    <svg
                    width="30"
                    height="30"
                    viewBox="0 0 31 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        d="M24.25 11.875H19.25V4.375H11.75V11.875H6.75L15.5 20.625L24.25 11.875ZM6.75 23.125V25.625H24.25V23.125H6.75Z"
                        fill="#BA5E98"
                    />
                    </svg>
                </div>
                <span className="ml-5 font-semibold text-[18px] leading-[30px] text-left">
                    {document.documentTitle}
                </span>
                </a>
            ))}                
            </div>

            <div className="mt-[40px]">
            <h3 className="text-[22px] font-semibold text-left mb-5">
                {t("confirmation.question")}
            </h3>
            <div className="flex flex-col align-start space-y-5">
                <a href={`${country}/faq`}>
                <div className="flex">
                    <div className="text-left text-[16px] md:text-[18px] font-semibold uppercase text-babymam-600">
                    {t("confirmation.faqs")}
                    </div>
                    <div className="flex justify-center items-center ml-[10px]">
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        d="M12 4.5L10.59 5.91L16.17 11.5H4V13.5H16.17L10.59 19.09L12 20.5L20 12.5L12 4.5Z"
                        fill="#BA5E98"
                        />
                    </svg>
                    </div>
                </div>
                </a>
                <a href={`${country}/contact`}>
                <div className="flex">
                    <div className="text-left text-[16px] md:text-[18px] font-semibold uppercase text-babymam-600">
                    {t("confirmation.contact")}
                    </div>
                    <div className="flex justify-center items-center ml-[10px]">
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        d="M12 4.5L10.59 5.91L16.17 11.5H4V13.5H16.17L10.59 19.09L12 20.5L20 12.5L12 4.5Z"
                        fill="#BA5E98"
                        />
                    </svg>
                    </div>
                </div>
                </a>
            </div>
            </div>
        </div>
        </div>
    </div>      
    </>
  );
};

export default Purchased;
