import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Button from "../components/Button";
import { useTranslation } from "react-i18next";
import InternalLink from "../components/InternalLink";

const WhyInsurance = () => {
  const { t } = useTranslation();
  const [showFixedComponent, setShowFixedComponent] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 750;
      setShowFixedComponent(scrollPosition > scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="w-full py-10 px-5 md:px-[32px] justify-center justify-items-center ">
        <div className="w-full justify-center text-center max-w-[1728px] mx-auto">
          <div className="md:mx-auto">
            <h1 className="text-[28px] md:text-[36px] font-bold font-serif text-babymam-900">
              {t("why_insurance.title")}
            </h1>
            <p className="mt-5 text-18 md:text-[20px] leading-[23px] md:leading-normal">
              {t("why_insurance.subtitle")}
            </p>
          </div>
        </div>
      </div>

      {/* SECTION 1 */}
      <div className="w-full bg-white justify-center ">
        <div className="flex flex-col md:flex-row justify-items-center max-w-[1728px] mx-auto">
          <div className="w-full md:w-3/12">
            <picture>
              {/* Mobile image */}
              <source
                media="(max-width: 767px)"
                srcSet={require("../images/why-1-mobile.png")}
              />
              {/* Pc image */}
              <img
                src={require("../images/why-1.png")}
                alt="couple"
                className="w-full h-auto md:h-full object-cover "
              />
            </picture>
          </div>
          <div className="w-full md:w-9/12 px-5 py-10 md:px-20">
            <h2 className="text-[24px] md:text-[30px] font-bold mb-5 text-babymam-900 leading-[30px] md:leading-normal">
              {t("why_insurance.section_title1")}
            </h2>
            <div className="leading-[20px] md:leading-[30px]">
              <p className="mt-[10px] text-[16px] md:text-[18px]">
                You are probably familiar with the concept of health and child
                insurance. But in most cases, either of them offers protection
                to you, your partner, and your child in the event of unexpected
                events
              </p>
              <p className="mt-[10px] text-[16px] md:text-[18px]">
                <span className="font-semibold">
                  Pregnancy insurance is the only protection that can be taken
                  out for an unborn child.{" "}
                </span>
                Since the financial support from society is limited in case
                something happens to an unborn child, pregnancy insurance
                becomes an essential supplement.
              </p>
              <p className="mt-[10px] text-[16px] md:text-[18px]">
                Essentially, it provides financial assistance in the event of,
                for example, premature birth or in case your child is born with
                some specific serious illness. In the case of Baby Mam -
                Pregnancy Insurance, it also covers accidents or complications
                for the baby, mother, and partner.
              </p>
              <p className="mt-[10px] text-[16px] md:text-[18px]">
                As a mother, you know that{" "}
                <span className="font-semibold">
                  pregnant women run increased risks of accidents and injuries.{" "}
                </span>
                Mainly because you will be going through many physical and
                hormonal changes that impact ligaments and joints in the spine.
                Studies show that these changes can make you prone to
                musculoskeletal injuries, and in the case of many different
                types of accidents, even minor ones, you can develop serious
                conditions in your body that affect the baby as well.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION 2 */}

      <div className="w-full bg-white justify-center justify-items-center  ">
        <div className="flex flex-col-reverse md:flex-row max-w-[1728px] mx-auto">
          <div className="w-full md:w-9/12 px-5 py-10 md:px-20">
            <h2 className="text-[24px] md:text-[30px] font-bold mb-5 text-babymam-900 leading-[30px] md:leading-normal">
              {t("why_insurance.section_title2")}
            </h2>
            <div className="leading-[20px] md:leading-[30px]">
              <p className="mt-[10px] text-[16px] md:text-[18px]">
                At Baby Mam we have been assisting pregnant families for years,
                and deeply understand how important it is for you to have peace
                of mind and go through your pregnancy with the lowest stress
                level possible.
              </p>
              <p className="mt-[10px] text-[16px] md:text-[18px] text">
                <span className="italic">
                  "What if my partner has an accident?", "What if my baby is
                  born prematurely?", “What if my baby is born with a
                  diagnosis?”{" "}
                </span>
                are tough questions that wander in your mind. Our aim is to be
                able to support you and your family for various things that can
                happen during pregnancy and in the early stages after the baby
                is born.
              </p>
              <p className="mt-[10px] text-[16px] md:text-[18px]">
                The focus is on covering accidents, certain birth defects and
                diagnosis of the child, hospitalization due to pregnancy
                complications and hospitalization of the child. Since our
                insurance is a complement to health or medical insurance, it
                doesn't focus on covering ultrasounds, routine controls, or
                birth costs. But rather events and complications that cause
                extra expenses for the family that other insurances don't
                necessarily cover.
              </p>
            </div>
          </div>
          <div className="w-full md:w-3/12">
            <picture>
              {/* Mobile image */}
              <source
                media="(max-width: 767px)"
                srcSet={require("../images/why-2-mobile.png")}
              />
              {/* Pc image */}
              <img
                src={require("../images/why-2.png")}
                alt="couple"
                className="w-full h-auto md:h-full object-cover "
              />
            </picture>
          </div>
        </div>
      </div>

      {/* SECTION 3 */}

      <div className="w-full bg-white justify-center justify-items-center  ">
        <div className="flex flex-col md:flex-row max-w-[1728px] mx-auto">
          <div className="w-full md:w-3/12">
            <picture>
              {/* Mobile image */}
              <source
                media="(max-width: 767px)"
                srcSet={require("../images/why-3-mobile.png")}
              />
              {/* Pc image */}
              <img
                src={require("../images/why-3.png")}
                alt="couple"
                className="w-full h-auto md:h-full object-cover "
              />
            </picture>
          </div>
          <div className="w-full md:w-9/12 px-5 py-10 md:px-20">
            <h2 className="text-[24px] md:text-[30px] font-bold mb-5 text-babymam-900 leading-[30px] md:leading-normal">
              {t("why_insurance.section_title3")}
            </h2>
            <div className="leading-[20px] md:leading-[30px]">
              <p className="mt-[10px] text-[16px] md:text-[18px]">
              Our goal is to help you focus on your health and your baby's, this is how easy it is to sign-up Baby Mam - Pregnancy Insurance:
              </p>
              <p className="mt-[10px] text-[16px] md:text-[18px]">
                Please beware that to get the most out of your insurance, it is
                best to sign up as soon as possible after you have done your
                first-trimester screening. We can't cover birth defects and
                diagnoses that are detected before the day you sign up.
              </p>
              <p className="mt-[10px] text-[16px] md:text-[18px]">
                If you decide to wait, know that you can sign-up for the insurance at
                any stage of your pregnancy until the day before birth.
                Independently of when you sign-up, <span className="font-semibold">
                the cover starts from week 16 for the parents and week 24 for
                the unborn child.
              </span>
              </p>
              
              <ul className="text-[16px] md:text-[18px] list-disc pl-5 md:pl-7 leading-5 md:leading-[30px]">
                <li className="mt-[5px]">
                  There's no need to fill in a health declaration to sign up.
                </li>
                <li className="mt-[5px]">
                  To start the process, you can sign up through the Baby Mam app
                  or website and receive immediate confirmation.
                </li>
                <li className="mt-[5px]">
                  The insurance coverage ends automatically 6 months after
                  birth. There's no need for extra actions to end it.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* STARTED */}
      <div className="bg-leafs bg-cover p-10 md:py-[30px] md:px-[10] ">
        <div className="w-full justify-center text-center max-w-[1728px] mx-auto ">
          <div className="flex flex-col md:w-6/12 rounded mx-auto items-center">
            <p className="text-babymam-900 text-[18px] md:text-[20px] leading-[23px] md:leading-normal">
              {t("why_insurance.leafs_desc")}
            </p>
            <div className="flex flex-row mt-5 w-full md:w-[31.25rem]">
              <InternalLink className="w-full" to="/product/checkout">
                <Button>{t("buttons.get_insurance")}</Button>
              </InternalLink>
            </div>
          </div>
        </div>

        {showFixedComponent && (
          <div className="flex w-full flex-col md:flex-row items-center md:items-stretch">
            <div className="flex flex-col fixed bottom-[20px] w-[94%] md:hidden   rounded-[10px] shadow-[0_4px_10px_0px_rgba(0,0,0,0.12);] h-fit mt-5 md:mt-0 bg-babymam-100">
              <InternalLink to="/product/checkout">
                <Button desk="18" mobile="16">
                  GET STARTED
                </Button>
              </InternalLink>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WhyInsurance;
